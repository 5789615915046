import React, { Component } from "react"
import { Modal, Input, message } from "antd"
//  import { __USER__ } from '../../../../constants/localConst';

import "../index.scss"
import DataApi from "../../../../data/api"

export default class Recovery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: "",
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.phoneNumber) {
      this.setState({
        phoneNumber: nextProps.data.phoneNumber,
      })
    }
  }

  onOk = () => {
    let param = {}
    if (this.state.phoneNumber) {
      param = {
        user_phone_numbers: this.state.phoneNumber,
      }
    } else {
      message.warn("错误")
      return
    }

    DataApi.user
      .recovery(param)
      .done(res => {
        this.props.succeedCallBack()
        message.success("该用户已移出黑名单")
      })
      .fail(err => {
        message.warn("恢复失败")
        console.log("恢复失败", err)
      })

    this.clearInput()
    this.props.close("dispenseModal")
  }

  onCancel = () => {
    this.clearInput()
    this.props.close("dispenseModal")
  }

  clearInput() {
    this.setState({
      phoneNumber: "",
    })
  }

  render() {
    let { phoneNumber } = this.state

    return (
      <div className="shell">
        <Modal
          visible={this.props.visible}
          onCancel={() => this.onCancel()}
          wrapClassName="shell"
          title={
            <div className="title-component">
              <span className="main-title">恢复用户：</span>
            </div>
          }
          onOk={() => this.onOk()}
        >
          <div className="info-elem">
            <span className="info-hint">当前用户：</span>
            <span className="info-input info-input-show">{phoneNumber}</span>
          </div>
        </Modal>
      </div>
    )
  }
}
