import React, { Component } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { dateFormat } from "../../util/filter"
import "./index.scss"
import { Table, Breadcrumb, Select, message } from "antd"
import Pagination from "../../components/Pagination"
import DataApi from "../../data/api"
import { SelectForm } from "../../components/CreateTasksSelectForm"
import Dispense from "./Popout/Dispense"
import OneDispense from "./Popout/OneDispense"

let columns = [
  {
    title: "任务ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "任务名称",
    dataIndex: "name",
    key: "name",
    render: role => {
      return (
        <div className="task-name-item">
          <p className="task-name">{role && role ? role : "--"}</p>
        </div>
      )
    },
  },
  {
    title: "城市",
    dataIndex: "city",
    key: "city",
    render: role => (
      <div className="city">
        <p>{role ? role : "--"}</p>
      </div>
    ),
  },
  {
    title: "区域",
    dataIndex: "district",
    key: "district",
    render: role => (
      <div className="district">
        <p>{role ? role : "--"}</p>
      </div>
    ),
  },
  {
    title: "地址",
    dataIndex: "address",
    key: "address",
    render: role => (
      <div className="address">
        <p>{role ? role : "--"}</p>
      </div>
    ),
  },
  {
    title: "场景",
    dataIndex: "scene",
    key: "scene",
    render: role => (
      <div className="scene">
        <p>{role ? role : "--"}</p>
      </div>
    ),
  },
  {
    title: "任务源",
    dataIndex: "gathering",
    key: "gathering",
    render: role => (
      <div className="gathering">
        <p>{role && role ? role : "--"}</p>
      </div>
    ),
  },
  {
    title: "发布渠道",
    dataIndex: "finishTime",
    key: "finishTime",
    render: role => (
      <div className="time-item">
        <p>{"--"}</p>
      </div>
    ),
  },
  {
    title: "发布时间",
    dataIndex: "firstTrialTime",
    key: "firstTrialTime",
    render: role => (
      <div className="time-item">
        <p>{"--"}</p>
      </div>
    ),
  },
  {
    title: "已知POI数",
    dataIndex: "summary",
    key: "summary",
    render: role => (
      <div className="time-item">
        <p>{role}</p>
      </div>
    ),
  },

  {
    title: "状态",
    dataIndex: "state",
    key: "state",
    render: role => {
      return (
        <div className="task-state">
          <span className="circle" style={{ backgroundColor: "#BDBDBD" }} />
          <span>{role}</span>
        </div>
      )
    },
  },
]
export default class CreateTasksPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      list: [],
      listTotalCount: 0,

      // 弹窗时选中项的数据
      actionItem: null,
      // 分发弹框的显隐
      dispenseModal: false,
      // 分发弹框的显隐
      oneDispenseModal: false,

      // 任务列表加载中
      taskListLoading: true,

      // 搜索条件
      searchCells: {},
      // 列表每页数据个数
      pageSize: 50,
      // 当前页数
      pageCurrent: 1,
    }
  }

  componentDidMount() {
    this.getManagementList()
  }

  /**
   * 获取列表
   */
  getManagementList(data) {
    this.setState({
      taskListLoading: true,
      selectedRowKeys: [],
    })
    data = {
      ...data,
      page_size: this.state.pageSize,
      page_index: this.state.pageCurrent,
    }
    DataApi.createTask
      .list(data)
      .done(res => {
        this.setState({
          taskListLoading: false,
        })
        try {
          this.setDataFromServer(res)
        } catch (err) {
          console.log(err.message)
        }
      })
      .fail(err => {
        message.error("搜索错误")
        console.log(err)
        this.setState({
          taskListLoading: false,
        })
      })
  }

  setDataFromServer(res) {
    const datas = res.data.list || []
    const list = []
    for (const index in datas) {
      const city = datas[index].place.address.context
        ? this.getObjectIncludeKeyFromArray(
            datas[index].place.address.context,
            "type",
            "City"
          )
        : null
      const district = datas[index].place.address.context
        ? this.getObjectIncludeKeyFromArray(
            datas[index].place.address.context,
            "type",
            "District"
          )
        : null
      const detailedAddress = ` ${datas[index].place.address.name}`
        ? ` ${datas[index].place.address.name}`
        : "-"
      const firstTrialTime = "-"
      list.push({
        id: datas[index].id ? datas[index].id : "-",
        name: datas[index].name ? datas[index].name : "-",
        city: city ? city.name : "-",
        district: district ? district.name : "-",
        scene: datas[index].type ? datas[index].type : "-",
        state: "待发布",
        firstTrialTime,
        address: detailedAddress,
        gathering: datas[index].source ? datas[index].source : "-",
        summary: datas[index].summary ? datas[index].summary : "-",
      })
    }
    this.setState({
      list,
      listTotalCount: res.data.total_count,
    })
  }

  getObjectIncludeKeyFromArray(array, keyName, keyValue) {
    for (const object of array) {
      if (object[keyName] === keyValue) {
        return object
      }
    }
    return null
  }

  // change = e => {
  //   let num = e;
  //   this.setState({num});
  //   if (e === '2') {
  //     this.getPrePublishList()
  //   } else {
  //     this.getPublishedList()
  //   }
  // };

  getRowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys })
      },
      getCheckboxProps: record => {
        return {
          disabled: record.name === "Disabled User",
          name: record.name,
        }
      },
      selectedRowKeys: this.state.selectedRowKeys,
    }
  }

  alterState(key, value) {
    this.setState({
      [key]: value,
    })
  }

  /**
   * 获取表头
   */
  getColumns() {
    if (!this.columns) {
      this.columns = columns.concat({
        title: "操作",
        key: "action",
        align: "center",
        render: (text, record) => {
          return (
            <div className="controller">
              <span className="action">
                <span
                  onClick={() => {
                    this.alterState("actionItem", text)
                    // this.releaseTaks(record.id)
                    this.openOneReleasetask()
                  }}
                >
                  创建
                </span>
              </span>
            </div>
          )
        },
      })
    }
    this.columns.forEach(item => {
      item.align = "center"
    })
    return this.columns
  }

  search(data) {
    // console.log(data)
    this.setState(
      {
        searchCells: data,
        selectedRowKeys: [],
        pageCurrent: 1,
      },
      () => {
        this.getManagementList(data)
      }
    )
  }

  openReleasetask = () => {
    this.setState({ dispenseModal: true })
  }
  openOneReleasetask = () => {
    this.setState({ oneDispenseModal: true })
  }
  changePage(page, pageSize) {
    this.setState(
      {
        pageCurrent: page,
      },
      () => {
        this.getManagementList(this.state.searchCells)
      }
    )
  }

  changePageSize(current, size) {
    this.setState(
      {
        pageSize: size,
        pageCurrent: 1,
      },
      () => {
        this.getManagementList(this.state.searchCells)
      }
    )
  }

  render() {
    let { list } = this.state
    let rowSelection = this.getRowSelection()
    let columns = this.getColumns()
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    // };

    return (
      <div className="task-management">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/createtasks">任务管控</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>任务发布</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="task-management-main">
          <SelectForm
            execute={data => {
              this.search(data)
            }}
            number={this.state.listTotalCount}
            openModal={() => {
              this.openReleasetask()
            }}
          />
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={list}
            rowKey="id"
            pagination={false}
            loading={this.state.taskListLoading}
            onChange={this.a}
          />
          <Pagination
            current={this.state.pageCurrent}
            total={this.state.listTotalCount}
            showQuickJumper={true}
            showSizeChanger={true}
            pageSizeOptions={["50", "200", "500"]}
            pageSize={this.state.pageSize}
            onChange={(page, pageSize) => {
              this.changePage(page, pageSize)
            }}
            onShowSizeChange={(current, size) => {
              this.changePageSize(current, size)
            }}
          />
        </div>
        <OneDispense
          close={key => this.alterState(key, false)}
          succeedCallBack={() => {
            this.getManagementList(this.state.searchCells)
          }}
          data={this.state.actionItem}
          visible={this.state.oneDispenseModal}
        />
        <Dispense
          close={key => this.alterState(key, false)}
          succeedCallBack={() => {
            this.getManagementList(this.state.searchCells)
          }}
          data={this.state.selectedRowKeys}
          visible={this.state.dispenseModal}
        />
      </div>
    )
  }
}
