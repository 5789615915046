import { Modal } from 'antd';

/**
 * 确认对话框
 * @params 
 */
export default (params) => {
  const {type, title, content, okText, onOk} = params;
  Modal[type]({
    title,
    content,
    okText: okText || '确定',
    onOk,
  })
}