import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Menu, Icon } from "antd"

const { SubMenu } = Menu
class Left extends Component {
  constructor(props) {
    super(props)

    const menuList = [
      {
        id: "dashboard",
        link: "/dashboard",
        name: "工作台",
        icon: "dashboard",
        inNavMenu: false,
      },
      {
        id: "project_man",
        link: "/project_man",
        name: "任务管理系统",
        icon: "appstore",
        inNavMenu: true,
        children: [
          {
            id: "create_task",
            link: "/createtasks",
            name: "任务创建",
            linkKey: "/createtasks",
            inNavMenu: true,
          },
          {
            id: "project_list",
            link: "/project/list",
            name: "项目列表",
            linkKey: "/project",
            inNavMenu: false,
          },
          {
            id: "task_managements",
            link: "/task/managements",
            name: "任务管理",
            linkKey: "/task",
            inNavMenu: true,
          },
          {
            id: "task_online",
            link: "/task_online",
            name: "任务上线",
            linkKey: "/task_online",
            inNavMenu: true,
          },
          {
            id: "field_verification",
            link: "/field/verification",
            name: "实地验证",
            linkKey: "/field",
            inNavMenu: true,
          },
          {
            id: "release",
            link: "/released",
            name: "发布管理",
            linkKey: "/released",
            inNavMenu: false,
          },
          {
            id: "photo",
            link: "/photo",
            name: "图片展示",
            linkKey: "/photo",
            inNavMenu: false,
          },
          {
            id: "manage_set",
            link: "/manage_set",
            name: "管理设置",
            linkKey: "/manage_set",
            inNavMenu: false,
          },
        ],
      },
      {
        id: "daily_report",
        link: "/daily_report",
        name: "报表管理系统",
        icon: "form",
        inNavMenu: true,
        children: [
          {
            id: "report_management",
            link: "/report_management",
            name: "报表管理",
            linkKey: "/report_management",
            inNavMenu: true,
          },
        ],
      },
      {
        id: "user_privileges",
        link: "/user_privileges",
        name: "用户权限管理系统",
        icon: "user",
        inNavMenu: true,
        children: [
          {
            id: "user_management",
            link: "/user_management",
            name: "用户管理",
            linkKey: "/user_management",
            inNavMenu: true,
          },
        ],
      },
    ]

    this.state = {
      visible: false,
      showMenu: true,
      collapsed: false,
      menuList,
      defaultOpenKeys: "",
    }
    // this.defaultOpenKeys = ["/project_man"]
    this.ids = []
    this.menuActive = this.menuActive.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    this.hideMenu()
  }

  hideMenu() {
    const { pathname } = this.props.location
    let firstName = pathname.substring(0, 2)
    if (firstName === "/r") {
      this.setState({ defaultOpenKeys: ["/daily_report"] })
    } else if (firstName === "/u") {
      this.setState({ defaultOpenKeys: ["/user_privileges"] })
    } else {
      this.setState({ defaultOpenKeys: ["/project_man"] })
    }
    // pathname 为 /task/details/id || /field/details/id 导航栏隐藏
    if (pathname.indexOf("/task/details") !== -1) {
      this.setState({ showMenu: false })
    }
    if (pathname.indexOf("/field/details") !== -1) {
      this.setState({ showMenu: false })
    }
  }

  onChange() {
    this.refreshDashboard()
    this.hide()
  }

  hide() {
    this.setState({
      visible: false,
    })
  }

  handleVisibleChange(visible) {
    this.setState({ visible })
  }

  menuActive(key) {
    return this.state.current === key ? "active" : ""
  }
  toggleMenu = () => {
    let { showMenu } = this.state
    this.setState({ showMenu: !showMenu })
  }

  menuRender(menus) {
    return menus.map(item => {
      if (!item.inNavMenu) {
        return null
      }
      if (!item.children || (item.children && !item.children[0].inNavMenu)) {
        return (
          <Menu.Item
            key={item.link}
            onClick={() => {
              this.props.history.push(item.link)
            }}
          >
            <span>{item.name}</span>
          </Menu.Item>
        )
      }
      return (
        <SubMenu
          key={item.link}
          title={
            <span>
              <Icon type={item.icon} />
              <span>{item.name}</span>
            </span>
          }
        >
          {this.menuRender(item.children)}
        </SubMenu>
      )
    })
  }
  render() {
    let { showMenu, menuList, visible } = this.state
    let curPath = this.props.location.pathname
    let curPathArr = [curPath]
    return (
      <div className={"pg-left" + (showMenu ? "" : " fold")}>
        <div className="left-head">
          <div className="toggle-btn" onClick={this.toggleMenu}>
            <Icon type="menu-fold" theme="outlined" style={{ color: "#fff" }} />
            <Icon
              type="menu-unfold"
              theme="outlined"
              style={{ color: "#fff" }}
            />
          </div>
          <h2 className="logo-name">
            <span>WAYZ采集运营管理后台</span>
          </h2>
        </div>
        <div className="pg-left-container">
          {this.state.defaultOpenKeys ? (
            <Menu
              selectedKeys={curPathArr}
              defaultOpenKeys={this.state.defaultOpenKeys}
              mode="inline"
              theme="dark"
            >
              {this.menuRender(menuList)}
            </Menu>
          ) : null}
        </div>
      </div>
    )
  }
}

export default withRouter(Left)
