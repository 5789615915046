import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./index.scss"
import TabPane from "./components/index"
import {
  Tabs,
  Breadcrumb
} from "antd"
// import DataApi from "../../data/api"



export default class Released extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      preList: [],
      titleArry: [
        {
          id: "1",
          value: "黑名单"
        },
        // {
        //   id: "2",
        //   value: "其他页面"
        // }
      ],
      num:"1"
    }
  }
  componentDidMount() {
   
  }
  
 
  render() {
    let { titleArry,num } = this.state
    return (
      <div className="release-list">
        <div className="pg-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">用户管理</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>黑名单</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="release-list-main">
          <Tabs activeKey={num} onChange={this.change}>
            {titleArry.map(item => {
              
                return (
                  <Tabs.TabPane tab={item.value} key={item.id}>
                      <TabPane   type={item.id} ></TabPane>
                  </Tabs.TabPane>
                )
              
            })}
          </Tabs>
        </div>
      </div>
    )
  }
}
