import React from 'react';
import { Tabs } from 'antd';
import Area from './Area';
import Scene from './Scene';
import Collection from './Collection';
import Task from './Task';

const { TabPane } = Tabs;
const tabsList = [
  {
    key: 'area',
    tab: '地区管理',
    component: <Area />
  },
  {
    key: 'scene',
    tab: '场景管理',
    component: <Scene />
  },
  {
    key: 'collection',
    tab: '采集管理',
    component: <Collection />
  },
  {
    key: 'task',
    tab: '任务管理',
    component: <Task />
  },
];


export default class ManageSet extends React.Component {
  render() {
    return (
      <div className='manage-set'>
        <Tabs defaultActiveKey={tabsList[0].key}>
          {
            tabsList.map(tabItem => {
              return (
                <TabPane tab={tabItem.tab} key={tabItem.key}>
                  { tabItem.component }
                </TabPane>
              )
            })
          }
        </Tabs>
      </div>
    )
  }
}