// default config
let api = "https://api.newayz.com"
let blacklistApi = "https://api.newayz.com"
let surveyTaskPointAPI = "https://api.newayz.com"
let loginApi = "https://api.newayz.com"
let districtsApi = "https://api.newayz.com"
let tempApi = "http://52.82.26.70:26031"
let auth = "https://cas.newayz.com"

// unknow contants
let mapapi = "https://indoormap-encrypt.newayz.com/map"
let appid = "wjPvEAGHmYlAM2D25c8rxxGB0Y"

let accessKey = "Wb3Dtw2Tc9teooyR55gJ5ehBa0"

let districtAccessKey = "WBUfTshv3RbkpSYf9e91O22ZX8"

switch (process.env.CHANNEL) {
  case "qa":
    api = "http://location-dev.newayz.com"
    blacklistApi = "http://location-dev.newayz.com"
    surveyTaskPointAPI = "http://location-dev.newayz.com"
    loginApi = "http://location-dev.newayz.com"
    districtsApi = "http://location-dev.newayz.com"
    tempApi = "http://location-dev.newayz.com"
    auth = "https://cas.newayz.com"
    accessKey = "Wb3Dtw2Tc9teooyR55gJ5ehBa0"
    districtAccessKey = undefined
    break
  case "dev":
    api = "http://api.newayz.local"
    blacklistApi = "http://api.newayz.local"
    surveyTaskPointAPI = "http://api.newayz.local"
    loginApi = "http://api.newayz.local"
    districtsApi = "http://172.2.2.11:21219"
    tempApi = "http://52.82.26.70:26031"
    auth = "https://cas.newayz.com"
    accessKey = "Wb3Dtw2Tc9teooyR55gJ5ehBa0"
    districtAccessKey = undefined
    break
  case "beta":
    api = "https://mirror.newayz.com"
    surveyTaskPointAPI = "https://mirror.newayz.com"
    loginApi = "https://mirror.newayz.com"
    districtsApi = "https://api.newayz.com"
    tempApi = "http://52.82.26.70:26031"
    auth = "https://cas.newayz.com"
    break
  default:
    break
}

module.exports = {
  ACCESS_KEY: accessKey,
  TASK_API: api,
  BLACK_LIST_API: blacklistApi,
  STPAPI: surveyTaskPointAPI,
  MAPAPI: mapapi,
  AUTH_URL: auth,
  APPID: appid,
  LOGININ_API: loginApi,
  DISTRICTS_API: districtsApi,
  TEMP_API: tempApi,
  DISTRICT_ACCESS_KEY: districtAccessKey,
  // STATIC_URL: 'http://localhost:3000/assets/img',
}
