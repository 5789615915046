import React from 'react';
import { Checkbox, Icon, Spin, message } from 'antd';
import _ from 'lodash';
import  updatePCD  from '../../util/pcdUpdater'; 
import './index.scss';


export default class ProvinceSelect extends React.Component {
  constructor(props) {
    super(props);
    this.selectedData = [];
    this.state = {
      showPopup: false,
      provinceIndex: undefined,
      cityIndex: undefined,
      provinceLoading: undefined,
      cityLoading: undefined,
      districtLoading: undefined,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.hidePopup);
  }

  componentDidUpdate(prevProps) {
    if (this.props.clearProvince !== prevProps.clearProvince
      && this.props.clearProvince) {
      this.clearSelect();
      this.introBoxRender();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hidePopup);
  }

  showPopup = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    if (this.state.showPopup) {
      return;
    }
    this.setState({showPopup: true}, this.getProvinceList);
  }

  hidePopup = () => {
    this.setState({showPopup: false, provinceIndex: undefined, cityIndex: undefined});
  }

  getProvinceList = () => {
    console.log(window.pcdMap);
    if (!window.pcdMap || (Array.isArray(window.pcdMap) && window.pcdMap.length === 0)) {
      this.setState({provinceLoading: true});
      updatePCD([], (list, noError) => {
        if (!noError && (!window.pcdMap || (Array.isArray(window.pcdMap) && window.pcdMap.length === 0))) {
          message.error('获取省列表失败');
        }
        this.setState({provinceLoading: false});
      })
    } else {
      this.setState({provinceLoading: false});
    }
  }


  selectHandler = (key, index) => {
    this.setState({[key]: index});
  }

  provinceClick = (provinceIndex) => {
    this.setState({
      provinceIndex, 
      cityIndex: undefined,
      cityLoading: true,
    }, () => {
      if (window.pcdMap[provinceIndex].children.length === 0) {
        updatePCD([provinceIndex], (list ,noError) => {
          if (!noError && window.pcdMap[provinceIndex].children.length === 0) {
            message.error('获取城市列表失败');
          }
          
          if (list[0] === provinceIndex) {
            this.setState({cityLoading: false});
          }
        })
      } else {
        this.setState({cityLoading: false})
      }
    });
  }

  cityClick = (cityIndex) => {
    this.setState({cityIndex, districtLoading: true}, () => {
      const { provinceIndex } = this.state;
      if (window.pcdMap[provinceIndex].children[cityIndex].children.length === 0) {
        updatePCD([provinceIndex, cityIndex], (list, noError) => {
          if (!noError && window.pcdMap[provinceIndex].children[cityIndex].children.length === 0) {
            message.error('获取区域列表失败');
          }

          if (list[0] === provinceIndex && list[1] === cityIndex) {
            this.setState({districtLoading: false})
          }
          
        })
      } else {
        this.setState({districtLoading: false});
      }
    });
  }

  provinceCheck = (e, index, checked) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation()
    if (checked) {
      this.selectedData[index] = [];
    } else {
     this.selectedData[index] = undefined;
    }
    this.forceUpdate();
  }

  provinceCheckResult = (provinceIndex) => {
    let result = false;
    if (this.selectedData[provinceIndex] && this.selectedData[provinceIndex].length === 0) {
      result = true;
    }
    return result;
  }

  cityCheck = (e, cityIndex, checked) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation()
    const { provinceIndex } = this.state;
    if (checked) {
      if (this.selectedData[provinceIndex] === undefined) {
        this.selectedData[provinceIndex] = [];
      } 
      this.selectedData[provinceIndex][cityIndex] = [];
      if (this.selectedData[provinceIndex].length === window.pcdMap[provinceIndex].children.length) {
        let hasUndefined = false;
        for(let i = 0; i < this.selectedData[provinceIndex].length; i++) {
          if (this.selectedData[provinceIndex][i] === undefined) {
            hasUndefined = true;
            break;
          }
        }
        if (!hasUndefined) {
          this.selectedData[provinceIndex] = [];
        }
      }
    } else {
      if (this.selectedData[provinceIndex].length === 0) {
        for(let i = 0; i < window.pcdMap[provinceIndex].children.length; i++) {
          if (i === cityIndex) {
            this.selectedData[provinceIndex][i] = undefined;
          } else {
            this.selectedData[provinceIndex][i] = [];
          }
        }
      } else {
        this.selectedData[provinceIndex][cityIndex] = undefined;
      }
      let allUndefined = true;
      for(let i = 0; i < this.selectedData[provinceIndex].length; i++) {
        if (this.selectedData[provinceIndex][i] !== undefined) {
          allUndefined = false;
          break;
        }
      }
      if (allUndefined) {
        this.selectedData[provinceIndex] = undefined;
      }
    }
    this.forceUpdate();
  }

  cityCheckResult = (cityIndex) => {
    let result = false;
    const { provinceIndex } = this.state;

    if (this.selectedData[provinceIndex]) {
      if (this.selectedData[provinceIndex].length === 0) {
        result = true;
      } else {
        if (this.selectedData[provinceIndex][cityIndex] && this.selectedData[provinceIndex][cityIndex].length === 0) {
          result = true;
        }
      }
    }
    return result;
  }

  districtCheck = (districtIndex, checked) => {
    const { provinceIndex, cityIndex } = this.state;
    if (checked) {
      if (this.selectedData[provinceIndex] === undefined) {
        this.selectedData[provinceIndex] = [];
        this.selectedData[provinceIndex][cityIndex] = [];
        this.selectedData[provinceIndex][cityIndex][districtIndex] = districtIndex;
      } else {
        if (this.selectedData[provinceIndex][cityIndex] === undefined) {
          this.selectedData[provinceIndex][cityIndex] = [];
          this.selectedData[provinceIndex][cityIndex][districtIndex] = districtIndex;
        } else {
          this.selectedData[provinceIndex][cityIndex][districtIndex] = districtIndex;
        }
      }
      if (this.selectedData[provinceIndex][cityIndex].length === window.pcdMap[provinceIndex].children[cityIndex].children.length) {
        let hasUndefined = false;
        for(let i = 0; i < this.selectedData[provinceIndex][cityIndex].length; i++) {
          if (this.selectedData[provinceIndex][cityIndex][i] === undefined) {
            hasUndefined = true;
            break;
          }
        }
        if (!hasUndefined) {
          this.selectedData[provinceIndex][cityIndex] = [];
          if (this.selectedData[provinceIndex].length === window.pcdMap[provinceIndex].children.length) {
            let cityHasUndefined = false;
            for(let i = 0; i < this.selectedData[provinceIndex].length; i++) {
              if (this.selectedData[provinceIndex][i] === undefined) {
                cityHasUndefined = true;
                break;
              }
            }
            if (!cityHasUndefined) {
              this.selectedData[provinceIndex] = [];
            }
          }
        }
      }
    } else {
      if (this.selectedData[provinceIndex].length === 0) {
        for(let i = 0; i < window.pcdMap[provinceIndex].children.length; i++) {
          this.selectedData[provinceIndex][i] = [];
        }
      }

      if (this.selectedData[provinceIndex][cityIndex].length === 0) {
        for(let i = 0; i < window.pcdMap[provinceIndex].children[cityIndex].children.length; i++) {
          if (i === districtIndex) {
            this.selectedData[provinceIndex][cityIndex][i] = undefined;
          } else {
            this.selectedData[provinceIndex][cityIndex][i] = i;
          }
        }
      } else {
        this.selectedData[provinceIndex][cityIndex][districtIndex] = undefined;

        let allUndefined = true;
        for(let i = 0; i < this.selectedData[provinceIndex][cityIndex].length; i++) {
          if (this.selectedData[provinceIndex][cityIndex][i] !== undefined) {
            allUndefined = false;
            break;
          }
        }
        if (allUndefined) {
          this.selectedData[provinceIndex][cityIndex] = undefined;
        }

        let cityAllUndefined = true;
        for(let i = 0; i < this.selectedData[provinceIndex].length; i++) {
          if (this.selectedData[provinceIndex][i] !== undefined) {
            cityAllUndefined = false;
            break;
          }
        }

        if (cityAllUndefined) {
          this.selectedData[provinceIndex] = undefined;
        }
      }
    }
    this.forceUpdate();
  }

  districtCheckResult = (districtIndex) => {
    let result = false;
    const { provinceIndex, cityIndex } = this.state;
    if (this.selectedData[provinceIndex]) {
      if (this.selectedData[provinceIndex].length === 0) {
        result = true;
      } else {
        if (this.selectedData[provinceIndex][cityIndex]) {
          if (this.selectedData[provinceIndex][cityIndex].length === 0) {
            result = true;
          } else {
            if (this.selectedData[provinceIndex][cityIndex][districtIndex] !== undefined) {
              result = true;
            }
          }
        }
      }
    }
    return result;
  }

  // toggleSelectOption = (showPopup) => {
  //   if (!showPopup) {
  //     this.initSelectState();
  //   }
  //   this.setState({showPopup});
  // }

  initSelectState = () => {
    this.setState({provinceIndex: undefined, cityIndex: undefined});
  }

  introBoxRender = () => {
    const result = [];
    for(let i = 0; i < this.selectedData.length; i++) {
      let resultItem = {};
      if (this.selectedData[i] !== undefined) {
        if (this.selectedData[i].length === 0) {
          resultItem = {};
          resultItem.provinceIndex = i;
          result.push(resultItem);
        } else {
          for(let cityIndex = 0; cityIndex < this.selectedData[i].length; cityIndex++) {
            if (this.selectedData[i][cityIndex] !== undefined) {
              if (this.selectedData[i][cityIndex].length === 0) {
                resultItem = {};
                resultItem.provinceIndex = i;
                resultItem.cityIndex = cityIndex;
                result.push(resultItem);
              } else {

                this.selectedData[i][cityIndex].map(districtIndex => {
                  if (this.selectedData[i][cityIndex][districtIndex] !== undefined) {
                    resultItem = {};
                    resultItem.provinceIndex = i;
                    resultItem.cityIndex = cityIndex;
                    resultItem.districtIndex = districtIndex;
                    result.push(resultItem);
                  }
                })
              }
            }
          }
        }
      }
    }

    
    const provinceArr = [];
    const cityArr = [];
    const districtArr = [];
    result.map(resultItem => {
      const { provinceIndex, cityIndex, districtIndex } = resultItem;
      if (districtIndex !== undefined) {
        districtArr.push(window.pcdMap[provinceIndex].children[cityIndex].children[districtIndex].label);
      } else {
        if (cityIndex !== undefined) {
          cityArr.push(window.pcdMap[provinceIndex].children[cityIndex].label);
        } else {
          if (provinceIndex !== undefined) {
            provinceArr.push(window.pcdMap[provinceIndex].label);
          }
        }
      }
    });

    this.props.getProvinceParams({
      provinces: provinceArr,
      cities: cityArr,
      districtes: districtArr
    });

    if (result.length === 0) {
      return <p className='province-placeholder'>请选择省市区</p>
    }

    return result.map((resultItem, index) => {
      const { provinceIndex, cityIndex, districtIndex } = resultItem;
      if (districtIndex !== undefined) {
        return (
          <div key={index}>
            <span>{window.pcdMap[provinceIndex].children[cityIndex].children[districtIndex].label}</span>
            <Icon type="close" onClick={(e) => {this.clearItem(e, resultItem)}}/>
          </div>
        ) 
      } else {
        if (cityIndex !== undefined) {
          return (
            <div key={index}>
              <span>{window.pcdMap[provinceIndex].children[cityIndex].label}</span>
              <Icon type="close" onClick={(e) => {this.clearItem(e, resultItem)}}/>
            </div>
          ) 
        } else {
          if (provinceIndex !== undefined) {
            return (
              <div key={index}>
                <span>{window.pcdMap[provinceIndex].label}</span>
                <Icon type="close" onClick={(e) => {this.clearItem(e, resultItem)}}/>
              </div>
            ) 
          }
        }
      }
    })
  }

  clearItem = (e, resultItem) => {
    // e.stopPropagation();
    const { provinceIndex, cityIndex, districtIndex } = resultItem;
    if (districtIndex !== undefined) {
      if (this.selectedData[provinceIndex][cityIndex].length === 0) {
        for(let i = 0; i < window.pcdMap[provinceIndex].children[cityIndex].children.length; i++) {
          if (i === districtIndex) {
            this.selectedData[provinceIndex][cityIndex][i] = undefined;
          } else {
            this.selectedData[provinceIndex][cityIndex][i] = i;
          }
        }
      } else {
        this.selectedData[provinceIndex][cityIndex][districtIndex] = undefined;

        let allUndefined = true;
        for(let i = 0; i < this.selectedData[provinceIndex][cityIndex].length; i++) {
          if (this.selectedData[provinceIndex][cityIndex][i] !== undefined) {
            allUndefined = false;
            break;
          }
        }
        if (allUndefined) {
          this.selectedData[provinceIndex][cityIndex] = undefined;
        }

        let cityAllUndefined = true;
        for(let i = 0; i < this.selectedData[provinceIndex].length; i++) {
          if (this.selectedData[provinceIndex][i] !== undefined) {
            cityAllUndefined = false;
            break;
          }
        }

        if (cityAllUndefined) {
          this.selectedData[provinceIndex] = undefined;
        }
      }
    } else {
      if (cityIndex !== undefined) {
        if (this.selectedData[provinceIndex].length === 0) {
          for(let i = 0; i < window.pcdMap[provinceIndex].children.length; i++) {
            if (i === cityIndex) {
              this.selectedData[provinceIndex][i] = undefined;
            } else {
              this.selectedData[provinceIndex][i] = [];
            }
          }
        } else {
          this.selectedData[provinceIndex][cityIndex] = undefined;
        }
        let allUndefined = true;
        for(let i = 0; i < this.selectedData[provinceIndex].length; i++) {
          if (this.selectedData[provinceIndex][i] !== undefined) {
            allUndefined = false;
            break;
          }
        }
        if (allUndefined) {
          this.selectedData[provinceIndex] = undefined;
        }
      } else {
        if (provinceIndex !== undefined) {
          this.selectedData[provinceIndex] = undefined;
        }
      }
    }
    this.forceUpdate();
  }

  clearSelect = () => {
    this.selectedData = [];
    this.forceUpdate();
  }

  render() {
    const { provinceIndex, cityIndex, showPopup } = this.state;
    return (
      <div className='component-province-select'>
        <div className='select-introduction'>
          <span className='intro-title'>省市区</span>
          <div className='intro-content' onClick={(e) => {this.showPopup(e)}}>
            <div className='intro-box'>
              {this.introBoxRender()}
            </div>
            {
            <div className={`list-box clear-fix ${showPopup ? '' : 'hide-list'}`}>
                <div className='list province-list'>
                  <Spin spinning={this.state.provinceLoading}>
                    <div className='spin-list'>
                      {
                        this.state.provinceLoading === false 
                        && Array.isArray(window.pcdMap)
                        && window.pcdMap.length > 0
                        && window.pcdMap.map((provinceItem, index) => {
                          return (
                            <div key={provinceItem.value} 
                                className={`province-item ${provinceIndex === index ? 'active' : ''}`}
                                onClick={() => {this.provinceClick(index)}}
                            >
                              {
                                this.selectedData[index] !== undefined && this.selectedData[index].length > 0
                                ? <div className='partical-select' onClick={(e) => {this.provinceCheck(e, index, true)}}>
                                    <span></span>
                                  </div>
                                : <Checkbox onChange={e => {this.provinceCheck(e, index, e.target.checked)}} 
                                            checked={this.provinceCheckResult(index)}
                                            className='checkbox' 
                                  />
                              }
                              <span className='name-mark'>{provinceItem.label}</span>
                              <span className='right-arrow'>></span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </Spin>
                </div>
                {
                  provinceIndex !== undefined && 
                  <div className='list city-list'>
                    <Spin spinning={this.state.cityLoading}>
                      <div className='spin-list'>
                        {
                          this.state.cityLoading === false &&
                          window.pcdMap[provinceIndex].children.map((cityItem, index) => {
                            return (
                              <div key={cityItem.value} 
                                  className={`city-item ${cityIndex === index ? 'active' : ''}`}
                                  onClick={() => {this.cityClick(index)}}
                              >
                                {
                                  this.selectedData[provinceIndex] && this.selectedData[provinceIndex][index] !== undefined && this.selectedData[provinceIndex][index].length > 0
                                  ? <div className='partical-select' onClick={(e) => {this.cityCheck(e, index, true)}}>
                                      <span></span>
                                    </div>
                                  : <Checkbox onChange={e => {this.cityCheck(e, index, e.target.checked)}} 
                                              checked={this.cityCheckResult(index)} 
                                              className='checkbox'
                                    />
                                }
                                <span className='name-mark'>{cityItem.label}</span>
                                <span className='right-arrow'>></span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Spin>
                  </div>
                }
                {
                  provinceIndex !== undefined
                  && cityIndex !== undefined &&
                  <div className='list district-list'>
                    <Spin spinning={this.state.districtLoading}>
                      <div className='spin-list'>
                        {
                          this.state.districtLoading === false &&
                          window.pcdMap[provinceIndex].children[cityIndex].children.map((districtItem, index) => {
                            return (
                              <div key={districtItem.value} className='district-item'>
                                <Checkbox className='checkbox' onChange={(e) => {this.districtCheck(index,e.target.checked)}} checked={this.districtCheckResult(index)} />
                                <span className='name-mark'>{districtItem.label}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Spin>

                  </div>
                }
            </div>
          }
          </div>
          <span className='clear-btn' onClick={this.clearSelect}>清空</span>
        </div>
      </div>
    )
  }
} 