import React from 'react';
import faildImg from '../../../img/faild.png';
import Viewer from '../../../components/Viewer';
import { message } from 'antd';
import venueConst from '../../../constants/venueConst';
import './Photo.scss';

export default class Photo extends React.Component {
  constructor(props) {
    super(props);
    this.bigImgSrc = faildImg;
    this.activeId = this.props.data.id;
    this.state = {
      ready: false,
      visible: false,
    }
  }
  
  showPhoto = () => {
    this.bigImgSrc = this.props.data.imageUrl;
    this.activeId = this.props.data.id;
    this.setState({visible: true});
  }

  closePhoto = () => {
    this.setState({visible: false});
  }

  imgError = () => {
    this.imgRef && (this.imgRef.src = faildImg);
    this.bigImgRef && (this.bigImgRef.src = faildImg);
  }

  imgLoad = () => {
    this.bigImgRef.style.height = document.body.clientHeight - 48 + 'px';
  }

  replaceImage = (type) => {
    const { list } = this.props;
    for(let index = 0; index < list.length; index++) {
      if (list[index].id === this.activeId) {
        if ((type === 1 && index < list.length - 1) || (type === -1 && index > 0)) {
          this.activeId = list[index + type].id;
          this.bigImgSrc = list[index + type].imageUrl ? list[index + type].imageUrl : faildImg;
          this.forceUpdate();
        } else {
          message.info("超出范围");
        }
        break;
      }
    }
  }

  render() {
    const { data } = this.props;
    return (
      <div className='temp-photo-item'>
        <div className="item-top">
          <div className="photo">
            <img src={data.imageUrl} 
                 alt="" 
                 onClick={this.showPhoto} 
                 onError={() =>{this.imgError()}}
                 ref={element => {this.imgRef = element}}
            />
          </div>
          <div className="msg">
            {
              data.floor &&
              <p>
              <span className='mark'>楼层：</span>
              <span className='content'>{data.floor}</span>
            </p>
            }
            <p>
              <span className='mark'>类型：</span>
              <span className='content'>{data.tag}</span>
            </p>
            <div className='position-info clear-fix'>
            <span className='mark'>名称：</span>
              <div className='pos-box'>
                <div className='pos-content'>{data.name}</div>
              </div>
            </div>
            <p>
              <span className='mark'>位置：</span>
              <span className='content'>{venueConst[data.venue] || data.venue}</span>
            </p>
          </div>
        </div>
        <Viewer visible={this.state.visible}
                onClose={this.closePhoto}
                images={[{src: this.bigImgSrc}]}
                replaceImage={(newIndex) => this.replaceImage(newIndex)}
                noNavbar={true}
                onMaskClick={this.closePhoto}
                zoomSpeed={0.2}
        />
      </div>
    )
  }
}