import React, { Component } from "react"
import { Modal, Input, message } from "antd"
// import { __USER__ } from '../../../../constants/localConst';

import "../index.scss"
import DataApi from "../../../../data/api"

export default class Dispense extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.data ? this.props.data.id : "",
      taskInfo: this.props.data ? this.props.data.name : "",
      phone: "",
      remarks: "",
      length: 0,
      data: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.taskInfo !== (this.props.data ? this.props.data.name : "")) {
      this.setState({
        taskInfo: this.props.data ? this.props.data.name : "",
      })
    }
    if (this.state.id !== (this.props.data ? this.props.data.id : "")) {
      this.setState({
        id: this.props.data ? this.props.data.id : "",
        length: this.props.data.length,
      })
    }
    if (this.state.data !== (this.props.data ? this.props.data : [])) {
      this.setState({
        length: this.props.data.length,
        data: this.props.data,
      })
    }
  }
  getUser() {
    return localStorage.getItem("__USER__")
  }
  onOk = () => {
    if (this.props.data.length > 0) {
      const dataTaksId = {
        place_ids: [...this.props.data],
        remarks: this.state.remarks,
        user_name: this.getUser(),
      }

      DataApi.createTask
        .release(dataTaksId)
        .done(res => {
          this.props.succeedCallBack()
          message.success("批量发布成功")
          this.setState(
            {
              length: 0,
            },
            () => {
              this.forceUpdate()
            }
          )
        })
        .fail(err => {
          message.warn("批量发布失败")
          console.log("批量发布失败", err)
        })
      this.clearInput()
      this.props.close("dispenseModal")
    } else {
      message.warn("请勾选任务")
    }
  }

  onCancel = () => {
    this.clearInput()
    this.props.close("dispenseModal")
  }

  clearInput() {
    this.setState({
      phone: "",
      remarks: "",
    })
  }

  render() {
    let { data } = this.props

    return (
      <div className="shell">
        <Modal
          visible={this.props.visible}
          onCancel={() => this.onCancel()}
          wrapClassName="shell"
          title={
            <div className="title-component">
              <span className="main-title">批量发布任务</span>
              {/*<span className="vice-title">确认框</span>*/}
            </div>
          }
          onOk={() => this.onOk()}
        >
          <div className="info-elem">
            <span className="info-hint">任务条数：</span>
            <span className="info-input info-input-show">
              {this.state.length}
            </span>
          </div>
          <div className="info-elem">
            <span className="info-hint">已知POI数：</span>
            <span className="info-input info-input-show">
              {this.state.taskInfo}
            </span>
          </div>
          <div className="info-elem">
            <span className="info-hint">平均POI数：</span>
            <span className="info-input info-input-show">
              {this.state.taskInfo}
            </span>
          </div>
          <div className="info-elem">
            <span className="info-hint">发布位置：</span>
            <span className="info-input info-input-show">
              {this.state.taskInfo}
            </span>
          </div>

          <div className="info-elem">
            <span className="info-hint">备注：</span>
            <Input.TextArea
              className="info-input"
              value={this.state.remarks}
              onChange={e => {
                this.setState({ remarks: e.target.value })
              }}
              placeholder="如有需要，请输入备注"
              rows={4}
              maxLength={50}
            />
          </div>
        </Modal>
      </div>
    )
  }
}
