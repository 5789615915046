// 依赖库
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';

// util
import Env from "../../util/env";
import { connect }  from 'react-redux';

// redux
import { setUser } from '../../redux/actions/user';

import { __USER__ } from '../../constants/localConst';


@connect(
  (state) => state,
  { setUser }
)
class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    }
  }

  beforeToRootPath(checkUrl) {
    const user = localStorage.getItem(__USER__);
    this.props['setUser'](user);
    window.location.href = checkUrl;
  }

  componentDidMount() {
    const checkUrl = _.get(this.props, 'location.state.referrer', window.location.href);
 
    let returnedAuthTicket = null;
    if (checkUrl) {
      const ticket = checkUrl.match(/ticket=(.*)$/);
      if (ticket) {
        returnedAuthTicket = ticket[1].replace('&', '');
      } else {
        const userName = localStorage.getItem(__USER__);
        if (userName) {
          Env.forceLogin();
          const redirectUrl = checkUrl.split('?')[0];
          this.beforeToRootPath(redirectUrl);
        } else {
          Env.goAuth(checkUrl);
        }
        return;
      }
    }

    if (returnedAuthTicket) {
      // localStorage.setItem('TICKET', returnedAuthTicket);
      Env.authWithTicket(returnedAuthTicket, () => {
        const redirectUrl = checkUrl.split('?')[0];
        this.beforeToRootPath(redirectUrl);
      })
    } else {
      Env.goAuth(checkUrl);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(Auth);