import React, { Component } from "react"
import Pagination from "../../../components/Pagination"
import Rcovery from "../Popout/Recovery"
import AddTo from "../Popout/AddTo"
import "./index.scss"
import { Table, message, Row, Col, Button } from "antd"
import DataApi from "../../../data/api"

let columns = [
  {
    align: "left",
    title: "序号",
    dataIndex: "order",
    key: "order",
    render: text => <span>{text ? text : "-"}</span>,
  },
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    render: text => <span>{text ? text : "-"}</span>,
  },
  {
    title: "手机号",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: text => <span>{text ? text : "-"}</span>,
  },
]
// const rowSelection = {
//     onChange: (selectedRowKeys, selectedRows) => {
//       selectList = selectedRows
//     },
//     onSelect: (record, selected, selectedRows) => {
//     },
//     onSelectAll: (selected, selectedRows, changeRows) => {
//     }
//   }

export default class TabPane extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      type: "",
      columns: [],
      cityList: [],
      currentCity: "",
      actionItem: null,
      dispenseModal: false,
      disAddToModal: false,
      // 当前页数
      pageCurrent: 1,
      listTotalCount: 0,
      loading: true,
      // 列表每页数据个数
      pageSize: 10,
    }
  }
  // componentWillReceiveProps (props) {
  //     this.initProps(props)
  //   }

  componentDidMount() {
    this.getManagementList()
  }
  getManagementList = data => {
    data = {
      ...data,
      page_size: this.state.pageSize,
      page_index: this.state.pageCurrent,
    }
    DataApi.user
      .blackList(data)
      .done(res => {
        //  console.log(res)
        this.initProps(res)
        this.setState({
          listTotalCount: res.totalCount,
          loading: false,
        })
      })
      .fail(res => {
        message.warn("获取列表失败")
      })
  }
  initProps(data) {
    let list = []
    let { users } = data

    if (users) {
      users.forEach((item, key) => {
        if (item) {
          item.order = `${key + 1}`
          list.push(item)
        }
      })

      this.setState({ list })
    }
  }

  changePage(page, pageSize) {
    this.setState(
      {
        pageCurrent: page,
        loading: true,
      },
      () => {
        this.getManagementList(this.state.searchCells)
      }
    )
  }

  changePageSize(current, size) {
    this.setState(
      {
        pageSize: size,
        pageCurrent: 1,
        loading: true,
      },
      () => {
        this.getManagementList(this.state.searchCells)
      }
    )
  }

  alterState(key, value) {
    this.setState({
      [key]: value,
    })
  }
  getColumns() {
    if (!this.columns) {
      this.columns = columns.concat({
        title: "操作",
        key: "action",
        align: "center",
        render: text => {
          return (
            <div className="controller">
              <span className="action">
                {
                  <span
                    onClick={() => {
                      this.alterState("dispenseModal", true)
                      this.alterState("actionItem", text)
                    }}
                  >
                    恢复
                  </span>
                }
              </span>
            </div>
          )
        },
      })
    }
    this.columns.forEach(item => {
      item.align = "center"
    })
    return this.columns
  }

  render() {
    let columns = this.getColumns()
    let { list } = this.state
    return (
      <div className="tabPane">
        <Row style={{ marginBottom: "20px" }} type="flex" justify="end">
          <Col>
            <Button
              className="btn"
              type="primary"
              onClick={() => {
                this.alterState("disAddToModal", true)
              }}
            >
              添加黑名单
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={list}
          pagination={false}
          loading={this.state.loading}
          // rowSelection={rowSelection}  //表格行是否可选择
        />
        <Pagination
          current={this.state.pageCurrent}
          total={this.state.listTotalCount}
          showQuickJumper={true}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "30", "50"]}
          pageSize={this.state.pageSize}
          onChange={(page, pageSize) => {
            this.changePage(page, pageSize)
          }}
          onShowSizeChange={(current, size) => {
            this.changePageSize(current, size)
          }}
        />

        <Rcovery
          close={key => this.alterState(key, false)}
          succeedCallBack={() => {
            this.getManagementList(this.state.searchCells)
          }}
          data={this.state.actionItem}
          visible={this.state.dispenseModal}
        />
        <AddTo
          close={key => this.alterState(key, false)}
          succeedCallBack={() => {
            this.getManagementList(this.state.searchCells)
          }}
          data={this.state.actionItem}
          list={this.state.list}
          visible={this.state.disAddToModal}
        />
      </div>
    )
  }
}
