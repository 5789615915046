/**
 * 延迟执行, 防止短时间内频繁执行
 * @constructor
 */
let Delay = function() {
  this.timer = null;
};
Delay.prototype = {
  run: function(fun, timeDelay) {
    let self = this;
    if (self.timer) {
      window.clearTimeout(self.timer);
    }
    self.timer = window.setTimeout(fun, timeDelay);
  }
};

/**
 * deferred
 * @constructor
 * @example
 * deferredObject.done(function(){...})[.fail(function(){...})]
 */
function Deferred() {
  this._done = [];
  this._fail = [];
}

Deferred.prototype = {
  execute: function(list, args) {
    let i = list.length;
    args = Array.prototype.slice.call(args);
    while (i--) list[i].apply(null, args);
  },
  resolve: function() {
    this.execute(this._done, arguments);
  },
  reject: function() {
    this.execute(this._fail, arguments);
  },
  done: function(callback) {
    this._done.push(callback);
    return this;
  },
  fail: function(callback) {
    this._fail.push(callback);
    return this;
  },
  onOk: function(callback) {
    return this.done(callback);
  },
  onCancel: function(callback) {
    return this.fail(callback);
  }
};

function copyToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export default {
  Delay: Delay,
  Deferred: Deferred,
  copyToClipboard
};
