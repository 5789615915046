import React from 'react';
import { Select, Input, message, List } from 'antd';
import DataApi from '../../data/api';
import Photo from './components/Photo';
import './index.scss';

const { Option } = Select;
const InputGroup = Input.Group;
const {Search} = Input;

const TASK_ID = 'task_id';
const BUILDING_ID = 'building_id';

const typeList = [
  {
    value: TASK_ID,
    label: 'customized_id',
  },
  {
    value: BUILDING_ID,
    label: 'building_id',
  }
];



export default class PhotoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true,
      type: typeList[0].value,
      id: '',
      buildingName: '',
    };
    this.dataList = [];
  }

  typeChange = type => {
    console.log(type);
    this.setState({type});
  }

  idChange = id => {
    this.setState({id});
  }

  search = () => {
    this.getDataList();
  }

  getDataList() {
    this.dataList = [];
    this.setState({ready: false, buildingName: ''});
    const { type, id } = this.state;
    DataApi.tempDetail.getInfo({[type]: id})
      .done(
        data => {
          // console.log(data);
          const id = this.getValidId(data, type);
          if (id) {
            this.getSurveyData(id);
          } else {
            this.setState({ready: true});
          }
        }
      )
      .fail(
        error => {
          this.setState({ready: true});
          console.error(error);
          message.error('数据请求出错');
        }
      )
  }

  getValidId(data, type) {
    try {
      let id = '';
      let createTime = 0;
      if (type === TASK_ID) {
        data.map(item => {
          if (item.tags.indexOf('learn') > -1
            && item.tags.indexOf('supply') < 0 
            && item.tags.indexOf('split') < 0 
            && item.createTime > createTime) {
            createTime = item.createTime;
            id = item.id;
          }
        });
      } else {
        if (data
          && data[0]
          && data[0].building
          && data[0].building.name) {
            this.setState({buildingName: data[0].building.name});
        }
        const floorJson = {};
        data.map(item => {
          if (item.tags.indexOf('learn') > -1
          && item.tags.indexOf('supply') < 0
          && item.tags.indexOf('split') < 0
          && item.floor
          && item.floor.name) {
            const floor = item.floor.name;
            if (floorJson[floor]) {
              if (item.createTime > floorJson[floor].createTime) {
                floorJson[floor] = {
                  createTime: item.createTime,
                  id: item.id,
                }
              }
            } else {
              floorJson[floor] = {
                createTime: item.createTime || 0,
                id: item.id,
              }
            }
          }
        });
        for(let floor in floorJson) {
          if (!floorJson[floor]) {
            break;
          }
          if (id) {
            id += `,${floorJson[floor].id}`;
          } else {
            id = floorJson[floor].id;
          }
        }
      }
      return id;
    } catch (error) {
      this.setState({ready: true});
      console.error('survey or task temp data error!');
      message.error('数据错误');
    }
  }

  getSurveyData(id) {
    DataApi.tempDetail.getInfo({survey_ids: id})
      .done(
        data => {
          // console.log(data);
          this.initData(data);
        }
      )
      .fail(
        error => {
          this.setState({ready: true});
          console.error(error);
          message.error('数据请求出错')
        }
      )
  }

  initData(data) {
    // console.log(data);
    if (data) {
      data.map(arrItem => {
        if (arrItem && arrItem.surveyPoints) {
          arrItem.surveyPoints.map(item => {
            const dataItem = {
              id: item.id,
              name: '',
              imageUrl: '',
              floor: arrItem.floor && arrItem.floor.name ? arrItem.floor.name : '',
              tag: '',
              venue: '',
            };
    
            if (item.place) {
              item.place.name && (dataItem.name = item.place.name);
              if (item.place.tags 
                && item.place.tags[0]
                && item.place.tags[0].name) {
                  item.place.tags[0].name && (dataItem.tag = item.place.tags[0].name)
              }
            }
            if (item.comments
              && item.comments[0]
              && item.comments[0].images
              && item.comments[0].images[0]) {
              item.comments[0].images[0].url && (dataItem.imageUrl = item.comments[0].images[0].url)
            }
            
            if (item.tags) {
              item.tags[0] && (dataItem.venue = item.tags[0]);
            }
            this.dataList.push(dataItem);
          })
        }
      })
    }
    // console.log('dataList', this.dataList);
    this.setState({ready: true});
  }
  render() {
    return (
      <div className='photo-page'>
        <div className='photo-header'>
          <InputGroup compact>
            <Select className='type-select'
                    value={this.state.type}
                    onChange={this.typeChange}
            >
              {
                typeList.map(item => {
                  return <Option value={item.value} key={item.value}>{item.label}</Option>
                })
              }
            </Select>
            <Search className='search-box'
                    value={this.state.id}
                    placeholder="请输入搜索内容"
                    enterButton="搜索"
                    onSearch={this.search}
                    onChange={(e) => {this.idChange(e.target.value)}}
            />
          </InputGroup>
        </div>
        <p className='building-name'>{this.state.buildingName}</p>
        <div>
          <List dataSource={this.dataList}
                pagination={true}
                split={false}
                loading={!this.state.ready}
                renderItem={item => (
                  <List.Item>
                    <Photo data={item} list={this.dataList}/>
                  </List.Item>
                )}
          />
        </div>
      </div>
    )
  }
}