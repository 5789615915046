import React from 'react';
import { Table } from 'antd';

const dataMock = [
  {
    id: '001',
    name: '地区1',
    lastUpdateTime: '2018-03-23 18:23',
    totalCount: 12,
  },
  {
    id: '002',
    name: '地区2',
    lastUpdateTime: '2018-03-23 18:23',
    totalCount: 12,
  },
  {
    id: '003',
    name: '地区3',
    lastUpdateTime: '2018-03-23 18:23',
    totalCount: 12,
  },
  {
    id: '004',
    name: '地区4',
    lastUpdateTime: '2018-03-23 18:23',
    totalCount: 12,
  },
];

export default class Area extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.dataList = [];
    this.columns = [];
  }
  componentDidMount() {
    this.getAreaList();
  }

  getAreaList() {
    // 此处发送get请求，获取area list data

    // temp
    dataMock.map(item => {
      item.areaId = item.id;
    })
    this.dataList = dataMock;
    this.initColumns();
  }

  initColumns() {
    const columns = [
      {
        title: '地区id',
        dataIndex: 'areaId',
      },
      {
        title: '地区',
        dataIndex: 'name',
      },
      {
        title: '最后修改时间',
        dataIndex: 'lastUpdateTime',
      },
      {
        title: '上线地区总数',
        dataIndex: 'totalCount',
      },
      {
        title: '操作',
        dataIndex: 'id',
        render: text => (
          <a onClick={() => {this.detailClick(text)}}>详情</a>
        )
      }
    ];
    columns.map(item => {
      item.key = item.name;
    });
    this.columns = columns;

    this.initEnd();
  }

  detailClick(text) {
    console.log(text);
  }

  initEnd() {
    this.setState({loading: false});
  }

  render() {
    return (
      <div className='manage-set-area'>
        <Table loading={this.state.loading}
               rowKey='id'
               dataSource={dataMock} 
               columns={this.columns}
        />
      </div>
    )
  }
}