import React from "react"
import { Button, Icon } from "antd"
import "./index.scss"

export default class FilterPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  togglePopup() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    return (
      <div className="component-filter-popup">
        <div className="select-header clear-fix">
          <span className="mark">筛选</span>
          <div
            className="show-select-btn"
            onClick={() => {
              this.togglePopup()
            }}
          >
            {this.state.visible ? (
              <div>
                <span>收起筛选</span>
                <Icon type="up" />
              </div>
            ) : (
              <div>
                <span>展开筛选</span>
                <Icon type="down" />
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className={`select-box ${this.state.visible ? "" : "hide-box"}`}>
          <div>{this.props.render()}</div>
        </div>
      </div>
    )
  }
}
