import React, {Component} from 'react'
import {Modal, Input, message} from 'antd'
// import { __USER__ } from '../../../../constants/localConst';

import '../index.scss'
import DataApi from "../../../../data/api";

export default class Stop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.data ? this.props.data.id : '',
      taskInfo: this.props.data ? this.props.data.name : '',
      phone: this.props.data ? this.props.data.phone : '',
      remarks: ""
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.taskInfo !== (this.props.data ? this.props.data.name : "")) {
      this.setState({
        taskInfo: this.props.data ? this.props.data.name : '',
      })
    }
    if (this.state.id !== (this.props.data ? this.props.data.id : "")) {
      this.setState({
        id: this.props.data ? this.props.data.id : '',
      })
    }
  }
  getUser() {
    return localStorage.getItem('__USER__');
  }
  onOk = () => {
    const dataTaksId = {
      'place_ids' : [this.state.id],
      'remarks' : this.state.remarks,
      'user_name': this.getUser()
    };
   
    DataApi.createTask.release(dataTaksId).done(res => {
      this.props.succeedCallBack();
      message.success('发布成功');

    }).fail(err => {
      message.warn('发布失败');
      console.log('批量发布失败', err);
    });
    this.clearInput();
    this.props.close('oneDispenseModal');
  };

  onCancel = () => {
    this.clearInput();
    this.props.close('oneDispenseModal');
  };

  clearInput() {
    this.setState({
      remarks: ""
    })
  }

  render() {
    
    return (
      <div className="shell">
        <Modal visible={this.props.visible}
               onCancel={() => this.onCancel()}
               wrapClassName='shell'
               title={(
                 <div className='title-component'>
                   <span className="main-title">发布任务</span>
                 </div>
               )}
               onOk={() => this.onOk()}
        >
          <div className="info-elem">
            <span className="info-hint">任务信息：</span>
            <span className="info-input info-input-show">{this.state.taskInfo}</span>
          </div>
          <div className="info-elem">
            <span className="info-hint">已知POI数：</span>
            <span className="info-input info-input-show">{this.state.phone}</span>
          </div>
          <div className="info-elem">
            <span className="info-hint">发布位置：</span>
            <span className="info-input info-input-show">{this.state.phone}</span>
          </div>
          <div className="info-elem">
            <span className="info-hint">备注：</span>
            <Input.TextArea className="info-input"
                            value={this.state.remarks}
                            onChange={(e) => {this.setState({remarks: e.target.value})}}
                            placeholder="如有需要，请输入备注"
                            rows={4}
                            maxLength={50}
            />
          </div>
        </Modal>
      </div>
    )
  }
}
