import React, { Component } from 'react'
import { Icon } from 'antd'
import Env from '../util/env';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      visible: false
    }
  }

  hide() {
    this.setState({
      visible: false
    })
  }

  handleVisibleChange(visible) {
    this.setState({ visible })
  }

  getUser() {
    return localStorage.getItem('__USER__');
  }

  logout() {
    Env.logout();
  }

  render() {
    // let user = this.state.user
    return (
      <div className="pg-header bor-b cf">
        <div className="head-right">
          <ul className="top-links">
            <li>
              <p>
                <span>
                  <Icon type="user" style={{ color: '#fff' }} />
                </span>
                <span style={{ color: '#fff' }}>{this.getUser()}</span>
              </p>
            </li>
            <li>
              <a href='javascript: void' onClick={() => {this.logout()}}>退出</a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Header
