// import HttpUtil from  'data/httpUtils'
export default {
  getLocalStorage: function (key, deleteAfterGet) {
    return this.get (window.localStorage, key, deleteAfterGet)
  },
  setLocalStorage: function (key, value) {
    this.set (window.localStorage, key, value)
  },
  removeLocalStorage: function (key) {
    this.remove (window.localStorage, key)
  },
  getSessionStorage: function (key, deleteAfterGet) {
    return this.get (window.sessionStorage, key, deleteAfterGet)
  },
  setSessionStorage: function (key, value) {
    this.set (window.sessionStorage, key, value)
  },
  removeSessionStorage: function (key) {
    this.remove (window.sessionStorage, key)
  },
  get: function (storage, key, deleteTag) {
    if (storage) {
      let value = storage.getItem (key)
      if (deleteTag) {
        storage.removeItem (key)
      }

      if (!storage.__memory && value && value.indexOf ('{') === 0) {
        try {
          return JSON.parse (value)
        } catch (e) {
          return value
        }
      }
      return value
    }
  },
  set: function (storage, key, value) {
    if (!storage) {
      return
    }
    if (!storage.__memory && typeof value === 'object') {
      try {
        value = JSON.stringify (value)
      } catch (e) {
        return false
      }
    }
    storage.setItem (key, value)

    return true
  },
  remove: function (storage, key) {
    storage.removeItem (key)
  }
}
