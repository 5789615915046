import React from 'react';
import { Select, DatePicker, Input } from 'antd';
import './index.scss';

const { Option } = Select;
const InputGroup = Input.Group;
const { Search } = Input;


export default class CheckCondition extends React.Component {
  constructor(props) {
    super(props);
    this.initState = {
      type: this.props.types[1].type,
      content: '',
    };
    this.state = {
      ...this.initState
    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.clearSearchContent !== prevProps.clearSearchContent
      && this.props.clearSearchContent === true) {
      this.setState({...this.initState});
    }
  }

  typeChange = (type) => {
    this.setState({type});
  }

  onSearch = (content) => {
    this.props.onSearch({type: this.state.type, content});
  }

  contentChange = (value) => {
    this.setState({content: value});
  }

  render() {
    const { className, types } = this.props;
    return (
      <div className={`component-check-condition ${className || ''}`}>
        <InputGroup compact>
          <Select value={this.state.type}
                  onChange={(value) => {this.typeChange(value)}}
          >
            {
              types.map(typeItem => {
                return <Option key={typeItem.type} value={typeItem.type}>{typeItem.name}</Option>
              })
            }
          </Select>
          <Search style={{width: 200, textAlign: 'center'}}
                  placeholder="请输入搜索内容"
                  enterButton="搜索"
                  value={this.state.content}
                  onChange={e => {this.contentChange(e.target.value)}}
                  onSearch={(value => {this.onSearch(value)})}
          />
        </InputGroup>
      </div>
    )
  }
}