//转成UTC+8时间
export const dateFormat = value =>  {
    if(value){
        return value + 0
    }
}
//去重
export const rep = arr =>{
    if(arr){
        var ret = []
        for (var i = 0; i < arr.length - 1; i++) {
            for (var j = i + 1; j < arr.length; j++) {
                if (arr[i].name === arr[j].name) {
                    arr.splice(j, 1)
                    j--
                }
            }
        }
        return ret
    }
}
// * 解析url参数
// * @example ?id=12345&a=b
// * @return Object {id:12345,a:b}
// */
export function urlParse() {
    let url = window.location.hash
    let obj = {}
    let reg = /[?&][^?&]+=[^?&]+/g
    let arr = url.match(reg)
    // ['?id=12345', '&a=b']
    if (arr) {
        arr.forEach(item => {
            let tempArr = item.substring(1).split('=')
            let key = decodeURIComponent(tempArr[0])
            let val = decodeURIComponent(tempArr[1])
            obj[key] = val
        })
    }
    return obj
}