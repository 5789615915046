import React from 'react';
import { Table, Button } from 'antd';

const dataMock = [
  {
    id: '001',
    operationLabel: '标签1',
    description: '描述1',
    lastUpdateTime: '2018-03-23 18:23',
    status: '状态1',
  },
  {
    id: '002',
    operationLabel: '标签2',
    description: '描述2',
    lastUpdateTime: '2018-03-23 18:23',
    status: '状态2',
  },
  {
    id: '003',
    operationLabel: '标签3',
    description: '描述3',
    lastUpdateTime: '2018-03-23 18:23',
    status: '状态3',
  },
  {
    id: '004',
    operationLabel: '标签4',
    description: '描述4',
    lastUpdateTime: '2018-03-23 18:23',
    status: '状态4',
  },
];

export default class Scene extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.dataList = [];
    this.columns = [];
  }
  componentDidMount() {
    this.getSceneList();
  }

  getSceneList() {
    // 此处发送get请求，获取area list data

    // temp
    dataMock.map(item => {
      item.sceneId = item.id;
    })
    this.dataList = dataMock;
    this.initColumns();
  }

  initColumns() {
    const columns = [
      {
        title: '场景id',
        dataIndex: 'sceneId',
      },
      {
        title: '运营标签',
        dataIndex: 'operationLabel',
      },
      {
        title: '描述',
        dataIndex: 'description',
      },
      {
        title: '最后修改时间',
        dataIndex: 'lastUpdateTime',
      },
      {
        title: '状态',
        dataIndex: 'status',
      },
      {
        title: '操作',
        dataIndex: 'id',
        render: text => (
          <div>
            <a style={{marginRight: '10px'}} onClick={() => {this.detailClick(text)}}>上线</a>
            <a style={{marginRight: '10px'}} onClick={() => {this.detailClick(text)}}>详情</a>
            <a onClick={() => {this.detailClick(text)}}>操作记录</a>
          </div>
          
        )
      }
    ];
    columns.map(item => {
      item.key = item.name;
    });
    this.columns = columns;

    this.initEnd();
  }

  detailClick(text) {
    console.log(text);
  }

  initEnd() {
    this.setState({loading: false});
  }

  createScene = () => {
    console.log('create scene');
  }

  render() {
    return (
      <div className='manage-set-scene'>
        <Button type='primary' 
                onClick={this.createScene}
        >
          +新增场景
        </Button>
        <Table loading={this.state.loading}
               rowKey='id'
               dataSource={dataMock} 
               columns={this.columns}
        />
      </div>
    )
  }
}