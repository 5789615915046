import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import Env from './util/env'
import './index.scss'
// import registerServiceWorker from './registerServiceWorker';
// Env.requestUserInfo (function (user) {
  ReactDOM.render (<App/>, document.getElementById ('root'));
// })
// registerServiceWorker();
