import React, { Component } from "react"
import { Modal, Input, message } from "antd"

import "../index.scss"
import DataApi from "../../../../data/api"

export default class AddTo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      phoneNumber: "",
    }
  }

  //   componentDidUpdate(prevProps, prevState, snapshot) {
  //     if (this.state.taskInfo !== (this.props.data ? this.props.data.name : "")) {
  //       this.setState({
  //         taskInfo: this.props.data ? this.props.data.name : '',
  //       })
  //     }
  //     if (this.state.id !== (this.props.data ? this.props.data.id : "")) {
  //       this.setState({
  //         id: this.props.data ? this.props.data.id : '',
  //       })
  //     }
  //   }

  onOk = () => {
    let param = {}
    if (this.state.phoneNumber) {
      let reg = /^[1]([3-9])[0-9]{9}$/
      if (!reg.test(this.state.phoneNumber)) {
        message.warn("手机号码格式不正确，请重新输入")
        return
      }
      param = {
        user: {
          name: this.state.name,
          phoneNumber: this.state.phoneNumber,
        },
      }
    } else {
      message.warn("请输入手机号")
      return
    }

    DataApi.user
      .addBlackList(param)
      .done(res => {
        this.props.succeedCallBack()
        message.success("该用户已加入黑名单")
      })
      .fail(err => {
        message.warn(err.error)
        console.log("流转失败", err)
      })
    this.clearInput()
    this.props.close("disAddToModal")
  }

  onCancel = () => {
    this.clearInput()
    this.props.close("disAddToModal")
  }

  clearInput() {
    this.setState({
      phoneNumber: "",
      name: "",
    })
  }

  render() {
    return (
      <div className="shell">
        <Modal
          visible={this.props.visible}
          onCancel={() => this.onCancel()}
          wrapClassName="shell"
          title={
            <div className="title-component">
              <span className="main-title">添加黑名单</span>
              {/*<span className="vice-title">确认框</span>*/}
            </div>
          }
          onOk={() => this.onOk()}
        >
          <div className="info-elem">
            <span className="info-hint" style={{ verticalAlign: "middle" }}>
              手机号：
            </span>
            <Input
              className="info-input"
              value={this.state.phoneNumber}
              onChange={e => {
                this.setState({ phoneNumber: e.target.value })
              }}
              placeholder="请输入手机号"
            />
          </div>
          <div className="info-elem">
            <span className="info-hint" style={{ verticalAlign: "middle" }}>
              用户名：
            </span>
            <Input
              className="info-input"
              value={this.state.name}
              onChange={e => {
                this.setState({ name: e.target.value })
              }}
              placeholder="请输入用户名"
            />
          </div>
        </Modal>
      </div>
    )
  }
}
