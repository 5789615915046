import React from 'react'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { LocaleProvider } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import AsyncWrap from './components/AsyncWrap'
import 'moment/locale/zh-cn'
import Env from './util/env'
import LeftMenu from './components/LeftMenu'
import Header from './components/Header'
import Logout from './routes/Passport/Logout'
import Auth from './routes/Auth'
import Home from './routes/Home'
import PhotoPage from './routes/PhotoPage';
import ManageSet from './routes/ManageSet';
import TaskOnline from './routes/TaskOnline';
import UserManagement from './routes/UserManagement';
import ReportManagement from './routes/ReportManagement';

import CreateTasks from './routes/CreateTasks';

import { GIT_MERGE, GIT_CHASH, VERSION } from './constants/version';
import './index.css'



const Dashboard = AsyncWrap(() => import('./routes/Dashboard'))
const ProjectList = AsyncWrap(() => import('./routes/Project/List'))
const ProjectCreate = AsyncWrap(() => import('./routes/Project/Create'))
const TaskCreate = AsyncWrap(() => import('./routes/Task/Create'))
const TaskManagement = AsyncWrap(() => import('./routes/Task/Management'))
const TaskManagements = AsyncWrap(() => import('./routes/Task/Managements'))
const TaskDetail = AsyncWrap(() => import('./routes/Task/Detail'))
const TaskDetails = AsyncWrap(() => import('./routes/Task/Details'))
const TaskList = AsyncWrap(() => import('./routes/Task/List'))
const FieldVerification = AsyncWrap(() => import('./routes/Field/Managements'))
const FieldDetails = AsyncWrap(() => import('./routes/Field/Details'))
const Released = AsyncWrap(() => import('./routes/Release/Released'))
const Test = AsyncWrap(() => import('./routes/Test'))
// 精度详情
const AccuracyDetail = AsyncWrap(() => import('./routes/Task/AccuracyDetail'))
// 打点
const DotPoint = AsyncWrap(() => import('./routes/Task/Dot'))
// 室内定位统计
const IndoorMap = AsyncWrap(() => import('./routes/IndoorMap'))
// const wrapWithOption = (Component, option) => {
//   return function(props) {
//     return <Component {...props} {...option} />
//   }
// }

function versionLog() {
  let CHANNEL = 'prod';
  if (process.env.CHANNEL) {
    CHANNEL = process.env.CHANNEL;
  }

  console.log('========= VERSION:', VERSION);
  console.log('========= GIT_MERGE:', GIT_MERGE);
  console.log('========= GIT_CHASH:', GIT_CHASH);
  console.log('========= CHANNEL:', CHANNEL);
  console.log('========= BUILD_TIME:', process.env.BUILD_TIME);
}

versionLog();


export default () => {
  return (
    <Provider store = { store } >
      <Router>
        <LocaleProvider locale={zhCN}>
          <div className="pg-wrap">
            <CommonRoute component={LeftMenu} />
            <div className="pg-body">
              <div className="pg-body-inner">
                <CommonRoute component={Header} />
                <Switch>
                  <PrivateRoute exact path="/" component={Home} />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute
                    exact
                    path="/project/list"
                    component={ProjectList}
                  />
                  <PrivateRoute
                    path="/project/create"
                    component={ProjectCreate}
                  />
                  <PrivateRoute path="/createtasks" component={CreateTasks} />
                  {/* <PrivateRoute
                    path="/task/management"
                    component={TaskManagement}
                  /> */}
                  <PrivateRoute
                    path="/task/managements"
                    component={TaskManagements}
                  />
                  <PrivateRoute path="/task/list" component={TaskList} />
                  <PrivateRoute path="/task/dot" component={DotPoint} />
                  <PrivateRoute path="/task/detail" component={TaskDetail} />
                  <PrivateRoute path="/task/details/:details_id/:tab?" component={TaskDetails} />
                  <PrivateRoute path="/task/create" component={TaskCreate} />
                  <PrivateRoute
                    path="/task/accuracyDetails"
                    component={AccuracyDetail}
                  />
                  <PrivateRoute path="/released" component={Released} />
                  <PrivateRoute path="/photo" component={PhotoPage} />
                  <PrivateRoute path="/manage_set" component={ManageSet} />
                  <PrivateRoute path="/task_online" component={TaskOnline} />     
                  <PrivateRoute path="/user_management" component={UserManagement} />     
                  <PrivateRoute path="/report_management" component={ReportManagement} />   
                    
                  <PrivateRoute path="/field/verification" component={FieldVerification} />     
                  <PrivateRoute path="/field/details/:details_id/:tab?" component={FieldDetails} />     
                               
                  <Route path="/auth" component={Auth} />

                  {/* <Route path="/test" component={Test} />
                  <Route path="/logout" component={Logout} />
                  <PrivateRoute exact path="/indoorMap" component={IndoorMap} /> */}
                </Switch>
              </div>
            </div>
          </div>
        </LocaleProvider>
      </Router>
    </Provider >
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return Env.isLogin() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/auth',
            state: { from: props.location, referrer: window.location.href }
          }}
        />
      )
    }}
  />
)

const CommonRoute = ({ component: Component }) => (
  Env.isLogin() && <Component />
)
