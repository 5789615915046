import React from "react"
import {
  Table,
  Button,
  Select,
  DatePicker,
  Input,
  Checkbox,
  Breadcrumb,
  message,
} from "antd"
import FormHeader from "../../components/FormHeader"
import FilterPopup from "../../components/FilterPopup"
import CheckCondition from "../../components/CheckCondition"
import Pagination from "../../components/Pagination"
import Popup from "./Popup"
import httpAPI from "../../data/api"
import moment from "moment"
import ProvinceSelect from "../../components/ProvinceSelect"
import { Link } from "react-router-dom"

import "./index.scss"

const { Option } = Select
const { RangePicker } = DatePicker
const InputGroup = Input.Group
const { Search } = Input
const CheckboxGroup = Checkbox.Group

const plainOptions = ["已完成", "已上线"]

const popupSelectList = [
  {
    value: "scene",
    label: "场景",
    placeholder: "请选择场景",
    selectOptions: [
      {
        value: "scene1",
        label: "场景1",
      },
      {
        value: "scene2",
        label: "场景2",
      },
      {
        value: "scene3",
        label: "场景3",
      },
    ],
  },
]

const popupTimeList = [
  {
    value: "finishTime",
    label: "完成时间",
  },
  {
    value: "onlineTime",
    label: "上线时间",
  },
]

const conditionConf = [
  {
    type: "task_id",
    name: "按任务ID",
  },
  {
    type: "task_name",
    name: "按任务名",
  },
  {
    type: "user_phone_number",
    name: "按手机号",
  },
]

export default class TaskOnline extends React.Component {
  constructor(props) {
    super(props)
    this.filterState = {}
    this.provinceParams = {}
    this.ids = []
    this.dataSource = []
    this.columns = []
    this.searchParams = {}

    this.initFilterState()

    this.initState = {
      scene: "",
      collector: "",

      checkedList: [],
      indeterminate: false,
      checkAll: false,
    }

    this.state = {
      ...this.initState,

      ...this.filterState,
      loading: true,
      listTotalCount: 0,
      pageSize: 10,
      pageCurrent: 1,
      selectedRowKeys: [],

      visible: false,
      modalType: "singleOnline",
      clear: false,

      clearProvince: false,
      clearSearchContent: false,
    }
  }

  initFilterState() {
    popupSelectList.map(item => {
      this.filterState[item.value] = undefined
    })
    popupTimeList.map(item => {
      this.filterState[item.value] = [null, null]
      this.filterState[`${item.value}String`] = undefined
    })
  }

  componentDidMount() {
    this.getTaskList({ task_states: plainOptions.join(",") })
  }

  endLoading() {
    this.setState({ loading: false })
  }

  getTaskList(params) {
    // 此处发送get请求，获取task list data
    this.setState({ loading: true })
    params = params || {}
    params = {
      task_types: "publish",
      ...params,
      page_size: this.state.pageSize,
      page_index: this.state.pageCurrent,
    }

    httpAPI.task
      .list(params)
      .done(res => {
        if (res.totalCount && res.totalCount > 0 && !res.tasks.tasks) {
          this.setState(
            {
              pageCurrent: res.pageCount,
            },
            () => {
              this.getTaskList(this.searchParams)
            }
          )
          return
        }

        if (res && res.tasks) {
          this.setState({ listTotalCount: res.totalCount || 0 })
          this.initDataSource(res.tasks.tasks)
        }
        this.setState({ loading: false })
      })
      .fail(error => {
        console.error(error)
        message.error("获取任务列表失败")
        this.setState({ loading: false })
      })
  }

  initDataSource(data) {
    this.dataSource = []
    if (data) {
      data.map(item => {
        const { id, name, type, totalPoi } = item
        const newItem = {
          id: id || "--",
          taskId: id || "--",
          name: name || "--",
          city: "--",
          area: "--",
          type,
          collector: {
            name: "--",
            phoneNumber: "--",
          },
          finishTime: {
            date: "--",
            time: "--",
          },
          onlineTime: {
            date: "--",
            time: "--",
          },
          totalPoi: totalPoi || "--",
          state: "--",
        }
        if (item.place && item.place.address && item.place.address.context) {
          if (
            item.place.address.context[1] &&
            item.place.address.context[1].name
          ) {
            newItem.city = item.place.address.context[1].name
          }
          if (
            item.place.address.context[2] &&
            item.place.address.context[2].name
          ) {
            newItem.area = item.place.address.context[2].name
          }
        }
        if (item.user) {
          if (item.user.name) {
            newItem.collector.name = item.user.name
          }
          if (item.user.phoneNumber) {
            newItem.collector.phoneNumber = item.user.phoneNumber
          }
        }
        if (item.state && item.state.name) {
          newItem.state = item.state.name
        }

        let finishTime, onlineTime
        if (item.activities && Array.isArray(item.activities)) {
          item.activities.map(activityItem => {
            if (
              activityItem.transition &&
              activityItem.transition.sourceState &&
              activityItem.transition.targetState
            ) {
              if (
                activityItem.transition.sourceState.name === "结算中" &&
                activityItem.transition.targetState.name === "已完成" &&
                (!finishTime ||
                  (finishTime && finishTime < activityItem.timestamp))
              ) {
                finishTime = activityItem.timestamp
              }
              if (
                activityItem.transition.targetState.name === "已上线" &&
                (!onlineTime ||
                  (onlineTime && onlineTime < activityItem.timestamp))
              ) {
                onlineTime = activityItem.timestamp
              }
            }
          })
        }

        if (finishTime) {
          newItem.finishTime.date = moment(finishTime).format("YYYY-MM-DD")
          newItem.finishTime.time = moment(finishTime).format("HH:mm:ss")
        }

        if (onlineTime) {
          newItem.onlineTime.date = moment(onlineTime).format("YYYY-MM-DD")
          newItem.onlineTime.time = moment(onlineTime).format("HH:mm:ss")
        }

        this.dataSource.push(newItem)
      })
    }
    this.initColumns()
  }

  initColumns() {
    const columns = [
      {
        title: "任务ID",
        dataIndex: "taskId",
      },
      {
        title: "任务名称",
        dataIndex: "name",
      },
      {
        title: "城市",
        dataIndex: "city",
      },
      {
        title: "区域",
        dataIndex: "area",
      },
      {
        title: "场景",
        dataIndex: "type",
        render: text => (
          <div className="scene">
            <p>{text}</p>
          </div>
        ),
      },
      {
        title: "采集员",
        dataIndex: "collector",
        render: text => (
          <div>
            <p>{text.name}</p>
            <p>{text.phoneNumber}</p>
          </div>
        ),
      },
      {
        title: "完成时间",
        dataIndex: "finishTime",
        render: text => (
          <div>
            <p>{text.date}</p>
            <p>{text.time}</p>
          </div>
        ),
      },
      {
        title: "上线时间",
        dataIndex: "onlineTime",
        render: text => (
          <div>
            <p>{text.date}</p>
            <p>{text.time}</p>
          </div>
        ),
      },
      {
        title: "总POI",
        dataIndex: "totalPoi",
      },
      {
        title: "状态",
        dataIndex: "state",
        render: text => {
          let circleColor
          switch (text) {
            case "已完成":
              circleColor = "blue"
              break
            case "已上线":
              circleColor = "green"
              break
            default:
              break
          }
          return (
            <div className="task-state">
              {circleColor && (
                <span
                  className="circle"
                  style={{ backgroundColor: circleColor }}
                />
              )}
              <span>{text ? text : "--"}</span>
            </div>
          )
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        render: (text, row) => (
          <div className="controller">
            {row.state === "已上线" ? (
              <a
                onClick={() => {
                  this.onLineHandler(false, [text])
                }}
              >
                下线
              </a>
            ) : (
              <a
                onClick={() => {
                  this.onLineHandler(true, [text])
                }}
              >
                上线
              </a>
            )}
            <Link to={`/task/details/${text}`} target="_blank">
              详情
            </Link>
            <Link
              to={{
                pathname: `/task/details/${text}/log`,
                state: { tab: "log" },
              }}
              target="_blank"
            >
              操作记录
            </Link>
          </div>
        ),
      },
    ]
    columns.map(item => {
      item.key = item.name
    })
    this.columns = columns

    this.initEnd()
  }

  detailClick(text) {
    console.log(text)
  }

  initEnd() {
    this.setState({ loading: false })
  }

  clearHandler = () => {
    this.clearProvince()
    this.clearSearchContent()
    this.setState({ ...this.initState })
    this.getTaskList({ task_states: plainOptions.join(",") })
  }

  popupSelectChange = (key, value) => {
    this.setState({ [key]: value })
  }

  popupTimeChange = (key, date, dateString) => {
    this.setState({ [key]: date, [`${key}String`]: dateString })
  }

  getProvinceParams = provinceParams => {
    this.provinceParams = provinceParams
  }

  clearProvince = () => {
    this.setState({ clearProvince: true }, () => {
      this.setState({ clearProvince: false })
    })
  }

  clearSearchContent = () => {
    this.setState({ clearSearchContent: true }, () => {
      this.setState({ clearSearchContent: false })
    })
  }

  inputChange = (key, value) => {
    this.setState({ [key]: value })
  }

  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    })
  }

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    })
  }

  filterPopupRender = () => {
    return (
      <div className="popup-content-box">
        <div className="popup-box">
          <div>
            <ProvinceSelect
              getProvinceParams={this.getProvinceParams}
              clearProvince={this.state.clearProvince}
            />
          </div>
        </div>
        <div className="popup-box">
          <div className="input-item">
            <span className="mark">场景</span>
            <Input
              className="input-box"
              placeholder="请选择场景"
              value={this.state.scene}
              onChange={e => {
                this.inputChange("scene", e.target.value)
              }}
            />
          </div>
          <div className="input-item">
            <span className="mark">采集员</span>
            <Input
              className="input-box"
              placeholder="请选择采集员"
              value={this.state.collector}
              onChange={e => {
                this.inputChange("collector", e.target.value)
              }}
            />
          </div>
        </div>
        <div className="popup-box group-check-box">
          <div className="states-header">
            <span className="header-mark">状态</span>
            <Checkbox
              indeterminate={this.state.indeterminate}
              onChange={this.onCheckAllChange}
              checked={this.state.checkAll}
            >
              全选
            </Checkbox>
          </div>
          <CheckboxGroup
            className="check-box-group"
            options={plainOptions}
            value={this.state.checkedList}
            onChange={this.onChange}
          />
        </div>
        <div className="popup-box">
          {popupTimeList.map(timeItem => {
            return (
              <div className="time-item" key={timeItem.value}>
                <span className="mark">{timeItem.label}</span>
                <RangePicker
                  key={timeItem.value}
                  value={this.state[timeItem.value]}
                  onChange={(date, dateString) => {
                    this.popupTimeChange(timeItem.value, date, dateString)
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  getRowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys })
      },
      getCheckboxProps: record => {
        return {
          disabled: record.name === "Disabled User",
          name: record.name,
        }
      },
      selectedRowKeys: this.state.selectedRowKeys,
    }
  }

  changePage(page, pageSize) {
    this.setState(
      {
        pageCurrent: page,
      },
      () => {
        const task_states =
          this.searchParams.task_states || plainOptions.join(",")
        this.getTaskList({ ...this.searchParams, task_states })
      }
    )
  }

  changePageSize(current, size) {
    this.setState(
      {
        pageSize: size,
        pageCurrent: 1,
      },
      () => {
        const task_states =
          this.searchParams.task_states || plainOptions.join(",")
        this.getTaskList({ ...this.searchParams, task_states })
      }
    )
  }

  onLineHandler = (online, ids) => {
    this.ids = [...ids]
    this.checkSelectedTask(online, ids)
  }

  checkSelectedTask(online, ids) {
    switch (ids.length) {
      case 0:
        message.error("请先选中一个任务")
        break
      case 1:
        this.setState({
          modalType: online ? "singleOnline" : "singleOffline",
          visible: true,
        })
        break
      default:
        this.setState({
          modalType: online ? "multiOnline" : "multiOffline",
          visible: true,
        })
        break
    }
  }

  onOk = noRefresh => {
    if (noRefresh) {
      this.setState({ visible: false })
    } else {
      const task_states =
        this.searchParams.task_states || plainOptions.join(",")
      this.getTaskList({ ...this.searchParams, task_states })
    }
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  onSearch = typeParams => {
    if (this.state.loading) {
      return
    }
    const searchParams = {
      ...this.initProvinceParams(),
    }

    const { type, content } = typeParams
    if (content) {
      searchParams[type] = content
    }

    const { scene, collector, checkedList } = this.state
    if (scene) {
      searchParams.task_scenes = scene
    }
    if (collector) {
      searchParams.user_name = collector
    }
    if (checkedList.length > 0) {
      searchParams.task_states = checkedList.join(",")
    } else {
      searchParams.task_states = plainOptions.join(",")
    }

    this.searchParams = searchParams

    this.setState(
      {
        selectedRowKeys: [],
        pageCurrent: 1,
      },
      () => {
        this.getTaskList(this.searchParams)
      }
    )
  }

  initProvinceParams = () => {
    const params = {}
    const { provinces, cities, districtes } = this.provinceParams
    if (provinces && provinces.length > 0) {
      params.province_names = provinces.join(",")
    }
    if (cities && cities.length > 0) {
      params.city_names = cities.join(",")
    }
    if (districtes && districtes.length > 0) {
      params.district_names = districtes.join(",")
    }
    return params
  }

  render() {
    return (
      <div className="task-online">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">项目管理</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>任务上线</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="header-box">
          <div>
            <FilterPopup render={this.filterPopupRender} />
          </div>

          <div className="control-box">
            <Button
              className="btn"
              type="primary"
              onClick={() => {
                this.onLineHandler(true, this.state.selectedRowKeys)
              }}
            >
              上线
            </Button>
            <Button
              className="btn"
              type="primary"
              onClick={() => {
                this.onLineHandler(false, this.state.selectedRowKeys)
              }}
            >
              下线
            </Button>
            <div className="check-condition-box">
              <span className="mark">搜索类型</span>
              <CheckCondition
                className="check-condition"
                types={conditionConf}
                onSearch={this.onSearch}
                clearSearchContent={this.state.clearSearchContent}
              />
            </div>
          </div>
        </div>
        <FormHeader
          total={this.state.listTotalCount}
          clearHandler={this.clearHandler}
        />
        <Table
          rowSelection={this.getRowSelection()}
          loading={this.state.loading}
          rowKey="id"
          dataSource={this.dataSource}
          columns={this.columns}
          pagination={false}
        />
        <Pagination
          current={this.state.pageCurrent}
          total={this.state.listTotalCount}
          showQuickJumper={true}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "30", "50"]}
          pageSize={this.state.pageSize}
          onChange={(page, pageSize) => {
            this.changePage(page, pageSize)
          }}
          onShowSizeChange={(current, size) => {
            this.changePageSize(current, size)
          }}
        />
        <Popup
          type={this.state.modalType}
          visible={this.state.visible}
          ids={this.ids}
          dataSource={this.dataSource}
          selectedRowKeys={this.state.selectedRowKeys}
          onOk={this.onOk}
          onCancel={this.onCancel}
        />
      </div>
    )
  }
}
