import HttpApi from '../data/api';
import { AUTH_URL, APPID } from "../constants/conf";
import * as _ from 'lodash';
import confirm from './confirm';
import { __USER__ ,__PHONE__} from '../constants/localConst';
const ACCESS_TOKEN = 'ACCESS_TOKEN';
const SESSION_ID = 'SESSION_ID';
const USER_ROLE = 'USER_ROLE';
const TICKET = 'TICKET';

function getLocalStorage() {
  const access_token = localStorage.getItem(ACCESS_TOKEN);
  const sessionid = localStorage.getItem(SESSION_ID);
  return { access_token, sessionid };
}



function errorHandler(error) {
  console.log('error', error);
  let errorTitle = '网络出现错误: network error';
  let errorContent = '';
  if (error) {
    errorTitle = `错误码: ${error.errcode}`;
    errorContent = error.description;
  }
  confirm({
    type: 'error', 
    title: errorTitle, 
    content: errorContent, 
    onOk: () => {
      env.logout();
    },
  });
}

const Auth = {
  isAuthenticated: false, // TODO
  user: {},
  // // authenticate(data, cb) {
  // //   setTimeout (function () {
  // //     if (data.name === 'admin' && data.password === 'root@1234') {
  // //       sessionStorage.setItem ('__USER__', data.name)
  // //       Auth.user = {
  // //         name: data.name
  // //       }
  // //       Auth.isAuthenticated = true
  // //       cb ({ error: 0 })
  // //     } else {
  // //       cb ({ error: 1, msg: '用户名或密码错误' })
  // //     }
  // //   }, 500)

  // // },
  // signout(cb) {
  //   this.isAuthenticated = false;
  //   this.user = {}
  //   localStorage.removeItem ('__USER__')
  //   // setTimeout (cb, 100);
  // },
  auth(userName,phone) {
    localStorage.setItem(__USER__, userName);
    localStorage.setItem(__PHONE__, phone);
    // localStorage.setItem(ACCESS_TOKEN, accessToken);
    // localStorage.setItem(USER_ROLE, user_role);
    Auth.user = {
      name: userName,
    };
    Auth.isAuthenticated = true;
  },

  goToAuth(redirectUri) {
    window.location.href = `${AUTH_URL}/cas/login?service=${encodeURIComponent(redirectUri)}`;
  },

  get authUri() {
    return window.location.origin + "/#/auth";
  }
};



const env = {
  login(cb) {
    const localParams = getLocalStorage();
    HttpApi.loginWithHeader(
      { 
        access_token: localParams.access_token, 
        sessionid: localParams.sessionid,
      }
    )
    .then(
      res => {
        if (res.status === 200) {
          const { username, sessionid, access_token, user_role } = res.data;
          Auth.auth(username,sessionid,access_token, user_role);
          if (cb) {
            cb();
          }
        }
      },
      error => {
        errorHandler(error);
      }
    )
  },
  isLogin() {
    return Auth.isAuthenticated
  },

  /**
   * 强制更新为已登录 
   */
  forceLogin() {
    Auth.isAuthenticated = true;
  },

  /**
   * 跳转到统一auth页面
   */
  goAuth(redirectUri) {
    Auth.goToAuth(redirectUri);
  },

  /**
   * 用ticket去换取token
   */
  authWithTicket(ticket, cb) {
    if (!ticket) {
      console.error("No valid ticket to auth with");
    } else {
      // const userName = 'admin';
      // Auth.auth(userName);
      // if (cb) {
      //   cb();
      // }
    console.log(ticket);
      HttpApi.login(
        {
          ticket
        }
      )
        .done(
          data => {
            Auth.auth(data.username,data.phone);
            if (cb) {
              cb();
            }
            
          }
        )
      .fail(
        error => {
          errorHandler(error);
        }
      )
    }
  },

  logout() {
    Auth.isAuthenticated = false;
    localStorage.removeItem(__USER__);
    // localStorage.removeItem(TICKET);
    // localStorage.removeItem(ACCESS_TOKEN);
    // localStorage.removeItem(SESSION_ID);
    // localStorage.removeItem(USER_ROLE);
    window.location.href = `${AUTH_URL}/cas/logout?service=${window.location.origin}`;
  },
}

export default env;

