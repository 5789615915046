import React, { Component } from "react"
import {
  Select,
  DatePicker,
  Icon,
  Input,
  Button,
  Modal,
  Upload,
  Cascader,
  message,
} from "antd"
import ProvinceSelect from "../ProvinceSelect"
import info from "../../img/info.png"
import "./index.scss"

import pcdData from "../../constants/pcdData"
import updatePCD from "../../util/pcdUpdater"

const { RangePicker } = DatePicker
const { Option } = Select
const InputGroup = Input.Group
const { Search } = Input
const { Dragger } = Upload

const plainOptions = [
  "未开始",
  "再分配",
  "进行中",
  "初审中",
  "初审不通过",
  "复审中",
  "复审不通过",
  "结算中",
  "结算不通过",
  "已完成",
  "已停止",
  "已上线",
]

export class SelectForm extends Component {
  constructor(props) {
    super(props)
    this.searchType = "1"

    this.provinceParams = {}

    this.state = {
      provinceList: [], // 省列表
      currentProvince: "", // 当前省
      cityList: [], // 城市列表
      districtList: [], // 区列表
      currentDistrict: "", // 当前区
      currentCity: "", // 当前城市
      provinceIndex: "",
      cityIndex: "",
      cityVisibal: true,
      districtVisibal: true,
      addModalVisible: false,
      searchVisibal: false,
      uploadFlie: [],
      hide: false,

      imperfectResult: "",
      list: [
        {
          key: "form2",
          data: [
            {
              key: 3,
              value: "场景",
              data: ["商场", "超市", "地铁站", "写字楼", "品牌店铺", "全选"],
              placeholder: "请选择场景",
            },
          ],
        },
      ],
      resultList: ["", "", "", "", "", "", "", "", "", ""],

      checkedList: [],
      indeterminate: false,
      checkAll: false,
      clearProvince: false,
    }
  }

  componentDidMount() {
    let { menu } = this.props
    this.setState({ menu })
    this.getProvinceList()
  }

  hideForm = () => {
    this.setState({ hide: !this.state.hide })
  }

  change(e, key) {
    let { resultList } = this.state
    resultList[key] = e
    this.setState({
      resultList,
    })
  }

  fileChange(info) {
    const uploadFlie = []
    if (info.fileList.length > 0) {
      const length = info.fileList.length
      uploadFlie.push(
        info.fileList[length - 1].originFileObj
          ? info.fileList[length - 1].originFileObj
          : info.fileList[length - 1]
      )
    }
    this.setState({
      uploadFlie,
    })
  }

  addFile() {
    const uploadFlie = [...this.state.uploadFlie]
    console.log("addFile", uploadFlie)

    this.setState({
      addModalVisible: false,
      uploadFlie: [],
    })
  }

  search(value) {
    const data = {}
    if (value !== "") {
      if (this.searchType === "0") {
        // 后端未实现
        data.task_id = value
      } else if (this.searchType === "1") {
        data.task_name = value
      } else if (this.searchType === "2") {
        data.user_phone_number = value
      }
    }
    if (!this.state.hide) {
      data.province_names = this.state.resultList.province_names
      data.city_names = this.state.resultList.city_names
      data.district_names = this.state.resultList.district_names
      this.state.resultList.forEach((elem, index) => {
        if (elem !== "") {
          switch (index) {
            case 0:
              data.province_names = elem
              break
            case 1:
              data.city_names = elem
              break
            case 2:
              data.district_names = elem
              break
            case 3:
              data.task_scenes = elem
              break
            case 4:
              data.task_states = elem
              break
            case 5:
              data.user_name = elem
              break
            case 6:
              // 后端没有该键
              data.submission_time = elem
              break
            case 7:
              // 后端没有该键
              data.audit_time = elem
              break
            case 8:
              // 后端没有该键
              data.approve_time = elem
              break
            case 9:
              // 后端没有该键
              data.close_time = elem
              break
            default:
              break
          }
        }
      })

      if (this.state.checkedList.length > 0) {
        data.task_states = this.state.checkedList.join(",")
      } else {
        data.task_states = undefined
      }

      const { provinces, cities, districtes } = this.provinceParams
      if (provinces && provinces.length > 0) {
        data.province_names = provinces.join(",")
      }
      if (cities && cities.length > 0) {
        data.city_names = cities.join(",")
      }
      if (districtes && districtes.length > 0) {
        data.district_names = districtes.join(",")
      }
    }
    if (data.task_scenes === "全选") {
      data.task_scenes = undefined
    }
    this.props.execute(data)
  }

  getProvinceParams = provinceParams => {
    this.provinceParams = provinceParams
  }

  wipeData() {
    this.setState(
      {
        clearProvince: true,
        searchVisibal: false,
        cityVisibal: true,
        districtVisibal: true,
      },
      () => {
        this.setState({ clearProvince: false })
      }
    )
    this.setState({
      resultList: ["", "", "", "", "", "", "", "", "", ""],
      currentDistrict: "", // 当前区
      currentCity: "", // 当前城市
      currentProvince: "",
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      imperfectResult: "",
    })
  }

  filterPCD(inputValue, path) {
    return path.some(
      option =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )
  }

  renderSearchControl(v, v1, resultList) {
    if (v.key === "form3") {
      return (
        <RangePicker
          style={{ width: "220px" }}
          onChange={(e, dataString) => {
            this.change(dataString, v1.key)
          }}
        />
      )
    } else if (v1.type === "status") {
      return (
        <Select
          style={{ width: "150px" }}
          placeholder={v1.placeholder}
          value={resultList[v1.key] || undefined}
          onChange={e => {
            this.change(e, v1.key)
          }}
          allowClear={true}
        >
          {v1.data.map((v2, k2) => {
            return (
              <Option value={v2} key={k2}>
                {v2}
              </Option>
            )
          })}
        </Select>
      )
    } else if (v.key === "form1") {
      let selected = [resultList[0], resultList[1], resultList[2]]
      if (!selected[0] && !selected[1] && !selected[2]) {
        selected = []
      }
      return (
        <Cascader
          style={{ width: "570px" }}
          options={pcdData}
          value={selected}
          onChange={(value, selectedOptions) => {
            for (let key = 0; key < 3; key++) {
              this.change(value[key], key)
            }
          }}
          placeholder={v1.placeholder}
          showSearch={{
            filter: this.filterPCD,
            matchInputWidth: true,
          }}
          changeOnSelect
        />
      )
    } else if (v.key === "form2") {
      return (
        <Select
          style={{ width: "150px" }}
          placeholder={v1.placeholder}
          value={resultList[v1.key] || undefined}
          onChange={e => {
            this.change(e, v1.key)
          }}
          allowClear={true}
        >
          {v1.data.map((v2, k2) => {
            return (
              <Option value={v2} key={k2}>
                {v2}
              </Option>
            )
          })}
        </Select>
      )
    }
  }

  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    })
  }

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    })
  }

  // ---------------------------------------->
  // getProvinceList()获取window.pcdMap  数据绑在了 window对象上

  getProvinceList = () => {
    if (
      !window.pcdMap ||
      (Array.isArray(window.pcdMap) && window.pcdMap.length === 0)
    ) {
      this.setState({ provinceLoading: true })
      updatePCD([], (list, noError) => {
        if (
          !noError &&
          (!window.pcdMap ||
            (Array.isArray(window.pcdMap) && window.pcdMap.length === 0))
        ) {
          message.error("获取省列表失败")
        }
        this.setState({ provinceLoading: false })
      })
    } else {
      this.setState({ provinceLoading: false })
    }
  }
  provinceClick = (v, k) => {
    let { resultList } = this.state
    resultList["province_names"] = v

    this.setState(
      {
        resultList,
        currentCity: "",
        currentDistrict: "",
        currentProvince: v,
        provinceIndex: k.key,
      },
      () => {
        if (window.pcdMap[k.key].children.length === 0) {
          updatePCD([k.key], (list, noError) => {
            if (!noError && window.pcdMap[k.key].children.length === 0) {
              message.error("获取城市列表失败")
            }

            if (list[0] === k.key) {
              this.setState({ cityVisibal: false, searchVisibal: true })
            }
          })
        } else {
          this.setState({ cityVisibal: false, searchVisibal: true })
        }
      }
    )
  }
  cityClick = (v, k) => {
    let { resultList } = this.state
    resultList["city_names"] = v
    resultList["district_names"] = ""
    this.setState(
      {
        resultList,
        currentCity: v,
        currentDistrict: "",
        cityIndex: k.key,
      },
      () => {
        const { provinceIndex } = this.state
        if (
          window.pcdMap[provinceIndex].children[k.key].children.length === 0
        ) {
          updatePCD([provinceIndex, k.key], (list, noError) => {
            if (
              !noError &&
              window.pcdMap[provinceIndex].children[k.key].children.length === 0
            ) {
              message.error("获取区域列表失败")
            }

            if (list[0] === provinceIndex && list[1] === k.key) {
              this.setState({ districtVisibal: false, searchVisibal: false })
            }
          })
        } else {
          this.setState({ districtVisibal: false, searchVisibal: false })
        }
      }
    )
  }
  districtClick = (v, k) => {
    let { resultList } = this.state
    resultList["district_names"] = v
    this.setState({
      resultList,
      currentDistrict: v,
      districtIndex: k.key,
    })
  }

  //省的点击事件

  handleData = () => {
    let provinceList = window.pcdMap.map(el => {
      return el.label
    })
    this.setState({ provinceList: provinceList })
  }

  // 市点击事件
  handleCityData = () => {
    this.setState({ cityList: [] })
    window.pcdMap.forEach((el, key) => {
      if (key == this.state.provinceIndex) {
        this.setState({ cityList: el.children })
      }
    })
  }
  // 区域点击事件
  handleDistrictData = () => {
    this.setState({ districtList: [] })
    window.pcdMap[this.state.provinceIndex].children.forEach((el, key) => {
      if (key == this.state.cityIndex) {
        this.setState({ districtList: el.children })
      }
    })
  }

  render() {
    let {
      hide,
      list,
      resultList,
      currentProvince,
      currentCity,
      provinceList,
      cityList,
      currentDistrict,
      cityVisibal,
      districtVisibal,
      searchVisibal,
      districtList,
    } = this.state

    return (
      <div className="select-form">
        <div className="title">
          <div>筛选</div>
          <div className="btn" onClick={this.hideForm}>
            <span>{hide ? "展开" : "收起"}</span>
            {hide ? <Icon type="down" /> : <Icon type="up" />}
          </div>
        </div>
        <div className={"form-box " + (hide ? "hide" : "")}>
          <div className="form1 province-box">
            <div className="filter">
              <span>省份: </span>
              <Select
                value={currentProvince}
                style={{ width: 200 }}
                onSelect={(e, k) => {
                  this.provinceClick(e, k)
                }}
                onDropdownVisibleChange={this.handleData}
              >
                {provinceList.map((c, i) => (
                  <Option key={i} value={c}>
                    {c}
                  </Option>
                ))}
              </Select>
              <span>城市: </span>
              <Select
                disabled={cityVisibal}
                placeholder="城市"
                value={currentCity}
                style={{ width: 200 }}
                onDropdownVisibleChange={this.handleCityData}
                // onChange={(e,currentCity)=>this.changeCity(e,currentCity)}
                onSelect={(e, k) => {
                  this.cityClick(e, k)
                }}
              >
                {cityList.map((c, i) => (
                  <Option key={i} value={c.label}>
                    {c.label}
                  </Option>
                ))}
              </Select>
              <span>区域: </span>
              <Select
                disabled={districtVisibal}
                placeholder="区域"
                value={currentDistrict}
                style={{ width: 200 }}
                onDropdownVisibleChange={this.handleDistrictData}
                onSelect={(e, k) => {
                  this.districtClick(e, k)
                }}
                // onChange={this.changeDistrict}
              >
                {districtList.map((c, i) => (
                  <Option key={i} value={c.label}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {list.map((v, k) => {
            return (
              <div className="form1" key={k}>
                {v.data.map((v1, k1) => {
                  return (
                    <div className="form-item" key={v1.key}>
                      <span>{v1.value}</span>
                      {this.renderSearchControl(v, v1, resultList)}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="export">
          <div className="btn-left">
            <Button
              type="primary"
              onClick={() => {
                this.props.openModal()
              }}
            >
              创建任务
            </Button>
          </div>
          <div className="btn-right">
            <span className="selectTypeSpan">搜索类型:</span>

            <InputGroup compact>
              <Select
                defaultValue={this.searchType}
                style={{ width: "100px" }}
                onChange={value => {
                  this.searchType = value
                }}
              >
                {/* <Option value="0">按任务ID</Option> */}
                <Option value="1">按任务名</Option>
                {/* <Option value="2">按手机号</Option> */}
              </Select>
              <Search
                value={this.state.imperfectResult}
                style={{ width: 200, textAlign: "center" }}
                placeholder="请输入搜索内容"
                enterButton="搜索"
                disabled={searchVisibal}
                onSearch={value => {
                  this.search(value)
                }}
                onChange={e => {
                  this.setState({ imperfectResult: e.target.value })
                }}
              />
            </InputGroup>
          </div>
        </div>
        <div className="form-head">
          <img src={info} alt="" />
          <span>
            共<em>{this.props.number || 0}</em>条记录
          </span>
          <span
            onClick={() => {
              this.wipeData()
            }}
          >
            清空已筛选条件
          </span>
        </div>

        <Modal
          visible={this.state.addModalVisible}
          onCancel={() => {
            this.setState({
              addModalVisible: false,
              uploadFlie: [],
            })
          }}
          onOk={() => {
            this.addFile()
          }}
        >
          <Dragger
            beforeUpload={() => false}
            onChange={info => this.fileChange(info)}
            fileList={this.state.uploadFlie}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">点击或拖拽任务信息文件到此处</p>
          </Dragger>
        </Modal>
      </div>
    )
  }
}
