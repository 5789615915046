import React from "react"
import {
  Table,
  Button,
  Select,
  DatePicker,
  Input,
  Checkbox,
  Breadcrumb,
  message,
  Card,
  Row,
  Col,
} from "antd"

import httpAPI from "../../data/api"
import moment from "moment"
import ProvinceSelect from "../../components/ProvinceSelect"
import { Link } from "react-router-dom"
import { VERSION } from "../../constants/version"
import { LOGININ_API, ACCESS_KEY } from "../../constants/conf"
import "./index.scss"

const { RangePicker } = DatePicker

const CheckboxGroup = Checkbox.Group

const plainOptions = ["已完成", "已上线"]

const dateFormat = "YYYY/MM/DD"

const popupSelectList = [
  {
    value: "scene",
    label: "场景",
    placeholder: "请选择场景",
    selectOptions: [
      {
        value: "scene1",
        label: "场景1",
      },
      {
        value: "scene2",
        label: "场景2",
      },
      {
        value: "scene3",
        label: "场景3",
      },
    ],
  },
]

const popupTimeList = [
  {
    value: "finishTime",
    label: "完成时间",
  },
  {
    value: "onlineTime",
    label: "上线时间",
  },
]

const conditionConf = [
  {
    type: "task_id",
    name: "按任务ID",
  },
  {
    type: "task_name",
    name: "按任务名",
  },
  {
    type: "user_phone_number",
    name: "按手机号",
  },
]

export default class TaskOnline extends React.Component {
  constructor(props) {
    super(props)
    this.filterState = {}
    this.provinceParams = {}
    this.ids = []
    this.dataSource = []
    this.columns = []
    this.searchParams = {}

    this.initFilterState()

    this.initState = {
      scene: "",
      collector: "",

      checkedList: [],
      indeterminate: false,
      checkAll: false,

      topList: [],
    }

    this.state = {
      ...this.initState,

      ...this.filterState,
      timestampParams: "",
      loading: true,
      listTotalCount: 0,
      pageSize: 10,
      pageCurrent: 1,
      selectedRowKeys: [],

      visible: false,
      modalType: "singleOnline",
      clear: false,

      clearProvince: false,
      clearSearchContent: false,
    }
  }

  initFilterState() {
    popupSelectList.map(item => {
      this.filterState[item.value] = undefined
    })
    popupTimeList.map(item => {
      this.filterState[item.value] = [null, null]
      this.filterState[`${item.value}String`] = undefined
    })
  }

  componentDidMount() {
    this.getDashboard()
    this.getCollectionList()
  }

  getDashboard = () => {
    httpAPI.reportManagement
      .dashboard()
      .done(res => {
        if (res) {
          console.log(res.data.dashboardReport)
          try {
            this.setState({ topList: res.data.dashboardReport })
          } catch (err) {
            console.log(err.message)
          }
        }
        this.setState({ loading: false })
      })
      .fail(error => {
        console.error(error)
        message.error("获取报表信息失败")
        this.setState({ loading: false })
      })
  }

  endLoading() {
    this.setState({ loading: false })
  }

  getCollectionList(params) {
    this.setState({ loading: true })

    httpAPI.reportManagement
      .collection(params)
      .done(res => {
        if (res) {
          console.log(res.data)
          try {
            this.initDataSource(
              res.data.collectCheckCityReports.collectCheckCityReports
            )
          } catch (err) {
            console.log(err.message)
          }
        }
        this.setState({ loading: false })
      })
      .fail(error => {
        console.error(error)
        message.error("获取任务列表失败")
        this.setState({ loading: false })
      })
  }

  initDataSource(data) {
    this.dataSource = []
    if (data) {
      console.log(data)
      data.forEach((item, i) => {
        const {
          cityName,
          totalCollectUserCount,
          totalCheckedPlaceCount,
          totalPlaceCount,
          totalFinishTaskCount,
          totalOnlineTaskCount,
          totalOnlinePlaceCount,
          totalCheckedTaskCount,
          totalTaskPassRate,
          totalCheckedPointCount,
          totalValidPlaceRate,
          totalInvalidPlaceRate,
          totalValidPointRate,
          totalInvalidPointRate,
        } = item
        const newItem = {
          cityName: cityName || "--",
          totalCollectUserCount: totalCollectUserCount || "--",
          totalCheckedPlaceCount: totalCheckedPlaceCount || "--",
          totalFinishTaskCount: totalFinishTaskCount || "--",
          totalOnlineTaskCount: totalOnlineTaskCount || "--",
          totalOnlinePlaceCount: totalOnlinePlaceCount || "--",
          totalCheckedTaskCount: totalCheckedTaskCount || "--",
          totalTaskPassRate: totalTaskPassRate ? `${totalTaskPassRate}%` : "--",
          totalCheckedPointCount: totalCheckedPointCount || "--",
          totalPlaceCount: totalPlaceCount || "--",
          totalValidPlaceRate: totalValidPlaceRate
            ? `${totalValidPlaceRate}%`
            : "--",
          totalInvalidPlaceRate: totalInvalidPlaceRate
            ? `${totalInvalidPlaceRate}%`
            : "--",
          totalValidPointRate: totalValidPointRate
            ? `${totalValidPointRate}%`
            : "--",
          totalInvalidPointRate: totalInvalidPointRate
            ? `${totalInvalidPointRate}%`
            : "--",
          key: i,
        }

        this.dataSource.push(newItem)
      })
    }
    this.initColumns()
  }

  initColumns() {
    const columns = [
      {
        title: "城市",
        dataIndex: "cityName",
      },
      {
        title: "总用户数",
        dataIndex: "totalCollectUserCount",
      },
      {
        title: "总POI数",
        dataIndex: "totalPlaceCount",
      },
      {
        title: "完成任务数",
        dataIndex: "totalFinishTaskCount",
      },
      {
        title: "上线任务数",
        dataIndex: "totalOnlineTaskCount",
      },
      {
        title: "上线POI数",
        dataIndex: "totalOnlinePlaceCount",
      },
      {
        title: "总审核任务数",
        dataIndex: "totalCheckedTaskCount",
      },
      {
        title: "任务通过率",
        dataIndex: "totalTaskPassRate",
      },
      {
        title: "总审核POI",
        dataIndex: "totalCheckedPlaceCount",
      },
      {
        title: "POI有效率",
        dataIndex: "totalValidPlaceRate",
      },
      {
        title: "POI无效率",
        dataIndex: "totalInvalidPlaceRate",
      },
      {
        title: "总审核采集点",
        dataIndex: "totalCheckedPointCount",
      },
      {
        title: "采集点有效率",
        dataIndex: "totalValidPointRate",
      },
      {
        title: "采集点无效率",
        dataIndex: "totalInvalidPointRate",
      },
    ]
    columns.forEach(item => {
      item.key = item.title
    })
    this.columns = columns
    this.initEnd()
  }

  detailClick(text) {
    console.log(text)
  }

  initEnd() {
    this.setState({ loading: false })
  }

  clearHandler = () => {
    this.clearProvince()
    this.clearSearchContent()
    this.setState({ ...this.initState })
    this.getTaskList({ task_states: plainOptions.join(",") })
  }

  popupSelectChange = (key, value) => {
    this.setState({ [key]: value })
  }

  popupTimeChange = (key, date, dateString) => {
    this.setState({ [key]: date, [`${key}String`]: dateString })
  }

  getProvinceParams = provinceParams => {
    this.provinceParams = provinceParams
  }

  clearProvince = () => {
    this.setState({ clearProvince: true }, () => {
      this.setState({ clearProvince: false })
    })
  }

  clearSearchContent = () => {
    this.setState({ clearSearchContent: true }, () => {
      this.setState({ clearSearchContent: false })
    })
  }

  inputChange = (key, value) => {
    this.setState({ [key]: value })
  }

  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    })
  }

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    })
  }

  filterPopupRender = () => {
    return (
      <div className="popup-content-box">
        <div className="popup-box">
          <div>
            <ProvinceSelect
              getProvinceParams={this.getProvinceParams}
              clearProvince={this.state.clearProvince}
            />
          </div>
        </div>
        <div className="popup-box">
          <div className="input-item">
            <span className="mark">场景</span>
            <Input
              className="input-box"
              placeholder="请选择场景"
              value={this.state.scene}
              onChange={e => {
                this.inputChange("scene", e.target.value)
              }}
            />
          </div>
          <div className="input-item">
            <span className="mark">采集员</span>
            <Input
              className="input-box"
              placeholder="请选择采集员"
              value={this.state.collector}
              onChange={e => {
                this.inputChange("collector", e.target.value)
              }}
            />
          </div>
        </div>
        <div className="popup-box group-check-box">
          <div className="states-header">
            <span className="header-mark">状态</span>
            <Checkbox
              indeterminate={this.state.indeterminate}
              onChange={this.onCheckAllChange}
              checked={this.state.checkAll}
            >
              全选
            </Checkbox>
          </div>
          <CheckboxGroup
            className="check-box-group"
            options={plainOptions}
            value={this.state.checkedList}
            onChange={this.onChange}
          />
        </div>
        <div className="popup-box">
          {popupTimeList.map(timeItem => {
            return (
              <div className="time-item" key={timeItem.value}>
                <span className="mark">{timeItem.label}</span>
                <RangePicker
                  key={timeItem.value}
                  value={this.state[timeItem.value]}
                  onChange={(date, dateString) => {
                    this.popupTimeChange(timeItem.value, date, dateString)
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  getRowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys })
      },
      getCheckboxProps: record => {
        return {
          disabled: record.name === "Disabled User",
          name: record.name,
        }
      },
      selectedRowKeys: this.state.selectedRowKeys,
    }
  }

  changePage(page, pageSize) {
    this.setState(
      {
        pageCurrent: page,
      },
      () => {
        const task_states =
          this.searchParams.task_states || plainOptions.join(",")
        this.getTaskList({ ...this.searchParams, task_states })
      }
    )
  }

  changePageSize(current, size) {
    this.setState(
      {
        pageSize: size,
        pageCurrent: 1,
      },
      () => {
        const task_states =
          this.searchParams.task_states || plainOptions.join(",")
        this.getTaskList({ ...this.searchParams, task_states })
      }
    )
  }

  onLineHandler = (online, ids) => {
    this.ids = [...ids]
    this.checkSelectedTask(online, ids)
  }

  checkSelectedTask(online, ids) {
    switch (ids.length) {
      case 0:
        message.error("请先选中一个任务")
        break
      case 1:
        this.setState({
          modalType: online ? "singleOnline" : "singleOffline",
          visible: true,
        })
        break
      default:
        this.setState({
          modalType: online ? "multiOnline" : "multiOffline",
          visible: true,
        })
        break
    }
  }

  onOk = noRefresh => {
    if (noRefresh) {
      this.setState({ visible: false })
    } else {
      const task_states =
        this.searchParams.task_states || plainOptions.join(",")
      this.getTaskList({ ...this.searchParams, task_states })
    }
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  onSearch = typeParams => {
    if (this.state.loading) {
      return
    }
    const searchParams = {
      ...this.initProvinceParams(),
    }

    const { type, content } = typeParams
    if (content) {
      searchParams[type] = content
    }

    const { scene, collector, checkedList } = this.state
    if (scene) {
      searchParams.task_scenes = scene
    }
    if (collector) {
      searchParams.user_name = collector
    }
    if (checkedList.length > 0) {
      searchParams.task_states = checkedList.join(",")
    } else {
      searchParams.task_states = plainOptions.join(",")
    }

    this.searchParams = searchParams

    this.setState(
      {
        selectedRowKeys: [],
        pageCurrent: 1,
      },
      () => {
        this.getTaskList(this.searchParams)
      }
    )
  }

  initProvinceParams = () => {
    const params = {}
    const { provinces, cities, districtes } = this.provinceParams
    if (provinces && provinces.length > 0) {
      params.province_names = provinces.join(",")
    }
    if (cities && cities.length > 0) {
      params.city_names = cities.join(",")
    }
    if (districtes && districtes.length > 0) {
      params.district_names = districtes.join(",")
    }
    return params
  }

  disabledDate = current => {
    return current && current > moment().add(-1, "day")
  }

  selectTimeParams = dateStringSelect => {
    const timeParams = {
      startTime: "",
      endTime: "",
    }
    const startTime = moment(`${dateStringSelect[0]} 00:00:00`).valueOf() / 1000
    timeParams.startTime = startTime
    const endTime = moment(`${dateStringSelect[1]} 23:59:59`).valueOf() / 1000
    timeParams.endTime = endTime
    return timeParams
  }
  dateChange = (_date, dateStringSelect) => {
    let params = {}
    if (dateStringSelect) {
      const timeParams = this.selectTimeParams(dateStringSelect)
      params.start_time = timeParams.startTime
      params.end_time = timeParams.endTime
      this.setState({ timestampParams: params })
    }
  }

  downloadReport = type => {
    let { timestampParams } = this.state
    if (timestampParams) {
      const version =
        "web-" +
        VERSION.split(".")
          .slice(0, 3)
          .join(".")

      window.location.href = `${LOGININ_API}/rope/v1/front/reports/${type}?Client-Info=${version}&access_key=${ACCESS_KEY}&start_time=${timestampParams.start_time}&end_time=${timestampParams.end_time}`
    } else {
      message.warn("请选择日期")
    }
  }

  /**
   * 数字增加千分号
   * @param data
   */
  dataWithComma = data => {
    if (!data) {
      return 0
    }
    var reg = /(\d{1,3})(?=(\d{3})+(\.\d*)?$)/g
    var num = data + ""
    return num.replace(reg, "$1,")
  }

  /**
   * 数字增加百分号
   * @param data
   */

  toPercent = point => {
    if (point === 0) {
      return 0
    }
    var str = Number(point * 100).toFixed()
    str += "%"
    return str
  }

  render() {
    let { topList } = this.state
    return (
      <div className="task-online">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">报表管理</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>采集总览表</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="header-box">
          <div>
            <div className="filter">
              <div className="item">
                <div className="name">
                  <span>总采集POI数</span>
                </div>
                <div className="count">
                  {this.dataWithComma(
                    topList.activeReport
                      ? topList.activeReport.totalPlaceCount
                        ? topList.activeReport.totalPlaceCount
                        : 0
                      : 0
                  )}
                </div>
                <div className="trend">
                  <div className="trend-item">
                    <span className="trend-name">日均采集POI</span>
                    <div className="trend-data">
                      <span className="data-count">
                        {this.dataWithComma(
                          topList.activeReport
                            ? topList.activeReport.dayPlaceCount
                              ? topList.activeReport.dayPlaceCount
                              : 0
                            : 0
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="name">
                  <span>月活跃用户数</span>
                </div>
                <div className="count">
                  {this.dataWithComma(
                    topList.activeReport
                      ? topList.activeReport.monthActiveUserCount
                        ? topList.activeReport.monthActiveUserCount
                        : 0
                      : 0
                  )}
                </div>
                <div className="trend">
                  <div className="trend-item">
                    <span className="trend-name">日活跃用户数量</span>
                    <div className="trend-data">
                      <span className="data-count">
                        {this.dataWithComma(
                          topList.activeReport
                            ? topList.activeReport.dayActiveUserCount
                              ? topList.activeReport.dayActiveUserCount
                              : 0
                            : 0
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item" style={{ alignItems: "center" }}>
                <div className="name">
                  <span>总上线任务数</span>
                </div>
                <div className="count">
                  {this.dataWithComma(
                    topList.totalOnlineTaskCount
                      ? topList.totalOnlineTaskCount
                      : 0
                  )}
                </div>
              </div>
              <div className="item" style={{ alignItems: "center" }}>
                <div className="name">
                  <span>待初审任务数</span>
                </div>
                <div className="count">
                  {this.dataWithComma(
                    topList.totalCheckingTaskCount
                      ? topList.totalCheckingTaskCount
                      : 0
                  )}
                </div>
              </div>
              <div className="item" style={{ alignItems: "center" }}>
                <div className="name">
                  <span>不合格任务数</span>
                </div>
                <div className="count">
                  {this.dataWithComma(
                    topList.totalCheckNotPassTaskCount
                      ? topList.totalCheckNotPassTaskCount
                      : 0
                  )}
                </div>
              </div>
              <div className="item" style={{ alignItems: "center" }}>
                <div className="name">
                  <span>无效任务数</span>
                </div>
                <div className="count">
                  {this.dataWithComma(
                    topList.totalInvalidTaskCount
                      ? topList.totalInvalidTaskCount
                      : 0
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="report-box">
          <Card title="导出功能" bordered={false}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <span className="customize">日期选择： </span>
                <RangePicker
                  defaultValue={[
                    this.state.lastSevenDateRange
                      ? moment(this.state.lastSevenDateRange)
                      : undefined,
                    this.state.dateString
                      ? moment(this.state.dateString)
                      : undefined,
                  ]}
                  format={dateFormat}
                  disabledDate={this.disabledDate}
                  onChange={this.dateChange}
                />
              </div>

              <Row
                style={{ marginBottom: "20px" }}
                type="flex"
                justify="end"
                gutter={20}
              >
                <Col>
                  <Button
                    className="btn"
                    type="primary"
                    onClick={() => {
                      this.downloadReport("collect_day_city_report")
                    }}
                  >
                    导出采集城市日报
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="btn"
                    type="primary"
                    onClick={() => {
                      this.downloadReport("check_day_city_report")
                    }}
                  >
                    导出审核城市日报
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="btn"
                    type="primary"
                    onClick={() => {
                      this.downloadReport("check_day_user_report")
                    }}
                  >
                    导出审核人员日报
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        <div className="table-card">
          <Card title="采集&审核汇总表" bordered={false}>
            <Table
              loading={this.state.loading}
              dataSource={this.dataSource}
              columns={this.columns}
              pagination={false}
            />
          </Card>
        </div>
      </div>
    )
  }
}
