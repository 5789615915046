import DataApi from '../data/api';


function updatePCD(pcArray, callback) {
  // Check array
  if (!Array.isArray(pcArray)) {
    console.error('pcArray is not Array!');
    callback(pcArray, false);
    return;
  }

  if (typeof callback !== 'function') {
    console.error('invalid callback!');
    callback(pcArray, false);
    return;
  }

  const params = {};

  if (pcArray.length === 0) {
    // Updating provinces
    if (window.pcdMap && window.pcdMap.length > 0) {
      console.error('window.pcdMap province is not empty!');
      callback(pcArray, false);
      return;
    }
  } else if (pcArray.length === 1) {
    if (!window.pcdMap || !window.pcdMap[pcArray[0]]) {
      console.error(`window.pcdMap[${pcArray[0]}] is invalid!`);
      callback(pcArray, false);
      return;
    }
    // Updating cities
    if (window.pcdMap[pcArray[0]].children.length > 0) {
      console.error('windows.pcdMap city is not empty!');
      callback(pcArray, false);
      return;
    }
    params['ids'] = window.pcdMap[pcArray[0]]['id'];
  } else if (pcArray.length === 2) {
    if (!window.pcdMap || !window.pcdMap[pcArray[0]] || !window.pcdMap[pcArray[0]].children[pcArray[1]]) {
      console.error(`window.pcdMap[${pcArray[0]}].children[${pcArray[1]}] is invalid!`);
      callback(pcArray, false);
      return;
    }
    // Updating districts
    if (window.pcdMap[pcArray[0]].children[pcArray[1]].children.length > 0) {
      console.error('windows.pcdMap district is not empty!');
      callback(pcArray, false);
      return;
    }
    params['ids'] = window.pcdMap[pcArray[0]].children[pcArray[1]]['id'];
  }

  DataApi.districts.list(params).done(res => {
    const container = []

    if (pcArray.length === 0) {
      if (window.pcdMap && window.pcdMap.length > 0) {
        console.warn('window.pcdMap province is not empty when parsing response!');
        callback(pcArray, false);
        return;
      } else {
        window.pcdMap = container;
      }
    } else if (pcArray.length === 1) {
      if (window.pcdMap[pcArray[0]].children.length > 0) {
        console.warn('windows.pcdMap city is not empty when parsing response!');
        callback(pcArray, false);
        return;
      } else {
        window.pcdMap[pcArray[0]].children = container;
      }
    } else if (pcArray.length === 2) {
      if (window.pcdMap[pcArray[0]].children[pcArray[1]].children.length > 0) {
        console.warn('windows.pcdMap district is not empty when parsing response!');
        callback(pcArray, false);
        return;
      } else {
        window.pcdMap[pcArray[0]].children[pcArray[1]].children = container;
      }
    }

    if (Array.isArray(res['placeLists'])) {
      const pl = res['placeLists'];
      for (const p of pl) {
        if (Array.isArray(p) && p.length > 0) {
          for (const val of p) {
            const obj = {
              value: val.name,
              label: val.name,
              id: val.id,
              code: val.code
            };
  
            if (pcArray.length < 2) {
              obj['children'] = [];
            }
  
            container.push(obj);
          }
        }
      }
      callback(pcArray, true);
    } else {
      console.error('invalid placeLists!');
      callback(pcArray, false);
    }
  }).fail((err) => {
    console.error('updatePCD error: ', err);
    callback(pcArray, false);
  })
}

export default updatePCD;
