import React from 'react';
import infoIcon from '../../img/info.png'
import './index.scss';

export default class FormHeader extends React.PureComponent {
  render() {
    const { total, clearHandler } = this.props;
    return (
      <div className="component-form-header">
          <img src={infoIcon} alt=""/>
          <span>
            共<em>{total}</em>条记录
          </span>
          <span onClick={() => {clearHandler()}}>
            清空已筛选条件
          </span>
        </div>
    )
  }
}