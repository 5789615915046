import ReactViewer from 'react-viewer'
import * as ReactDOM from 'react-dom';
import 'react-viewer/dist/index.css';
import React from "react";
import ReViewerCore from "./RejViewerCore";

export  default class Viewer extends ReactViewer{
  constructor(props){
    super(props);
  }

  renderViewer() {
    if (this.props.visible || this.component) {
      if (!this.container) {
        if (this.props.container) {
          this.container = this.props.container;
        } else {
          if (!this.defaultContainer) {
            this.setDefaultContainer();
          }
          this.container = this.defaultContainer;
          document.body.appendChild(this.container);
        }
      }
      let instance = this;
      ReactDOM.unstable_renderSubtreeIntoContainer(
        this,
        <ReViewerCore
          {...this.props}
        />,
        this.container,
        function () {
          instance.component = this;
        },
      );
    }
  }
}
