import ViewerCore from "react-viewer/lib/ViewerCore";

export  default class ReViewerCore extends ViewerCore{
  constructor(props){
    super(props);
  }

  handleChangeImg = (newIndex) => {
    this.props.replaceImage(newIndex);
  }

  componentDidUpdate(prevProps){
    super.componentDidUpdate(prevProps);
    if (JSON.stringify(this.props.images[0]) !== JSON.stringify(prevProps.images[0])){
      this.updateImage(this.props.images[0]);
    }
  }
  updateImage(image){
    let activeImage = image;
    let loadComplete = false;
    let img = new Image();
    this.setState({
      loading: true,
      loadFailed: false,
    }, () => {
      img.onload = () => {
        if (!loadComplete) {
          this.loadImgSuccess(activeImage, img.width, img.height, true);
        }
      };
      img.src = activeImage.src;
      if (img.complete) {
        loadComplete = true;
        this.loadImgSuccess(activeImage, img.width, img.height, true);
      }
    });
  }
}
