import React from "react"
import { Modal, Input, message } from "antd"
import httpAPI from "../../../data/api"
import "./index.scss"
export default class Popup extends React.Component {
  constructor(props) {
    super(props)
    this.dataSource = []
    this.state = {
      remarks: "",
      loading: false,
    }
  }

  onCancel = () => {
    this.props.onCancel()
  }

  onOk = () => {
    if (this.dataSource.length > 1) {
      message.warn("暂不支持批量功能")
      this.props.onOk(true)
      return
    }

    const params = {
      taskId: this.dataSource[0].id,
      patch: true,
      surveyTask: {},
      user: {
        id: 1,
        name: localStorage.getItem("__USER__"),
      },
    }

    if (this.props.type === "singleOnline") {
      params.surveyTask.state = "published"
    }
    if (this.props.type === "singleOffline") {
      params.surveyTask.state = "submitted"
    }
    const taskId = this.dataSource[0].id
    this.setState({ loading: true })
    httpAPI.taskOnline
      .update(taskId, params)
      .done(res => {
        message.success("更新成功")
        this.setState({ loading: false })
        this.props.onOk()
      })
      .fail(error => {
        console.error(error)
        message.error(error.message)
        this.setState({ loading: false })
      })
    this.props.onOk(true)
  }

  initDataSource(type) {
    this.dataSource = []
    const { ids, dataSource } = this.props
    switch (type) {
      case "singleOnline":
      case "singleOffline":
        for (let i = 0; i < dataSource.length; i++) {
          if (dataSource[i].id === ids[0]) {
            this.dataSource = [dataSource[i]]
            break
          }
        }
        break
      case "multiOnline":
      case "multiOffline":
        for (let i = 0; i < dataSource.length; i++) {
          if (ids.indexOf(dataSource[i] > -1)) {
            this.dataSource.push(dataSource[i])
          }
        }
        break
      default:
        break
    }
  }

  checkType() {
    const { type } = this.props
    let title = ""
    let content = ""
    this.initDataSource(type)
    switch (type) {
      case "singleOnline":
        title = "上线任务"
        content = this.singleOnlineRender()
        break
      case "singleOffline":
        title = "下线任务"
        content = this.singleOfflineRender()
        break
      case "multiOnline":
        title = "批量上线任务"
        content = this.multiOnlineRender()
        break
      case "multiOffline":
        title = "批量下线任务"
        content = this.multiOfflineRender()
        break
      default:
        break
    }

    this.title = (
      <div className="title">
        <span>{title}</span>
      </div>
    )
    this.content = content
  }

  singleOnlineRender() {
    return (
      <div>
        <div className="modal-item">
          <span className="mark">任务信息</span>
          <span className="content">{this.dataSource[0].name}</span>
        </div>
        <div className="modal-item">
          <span className="mark">已知POI数</span>
          <span className="content">{this.dataSource[0].totalPoi}</span>
        </div>
      </div>
    )
  }

  singleOfflineRender() {
    return (
      <div>
        <div className="modal-item">
          <span className="mark">任务信息</span>
          <span className="content">{this.dataSource[0].name}</span>
        </div>
        <div className="modal-item">
          <span className="mark">已知POI数</span>
          <span className="content">{this.dataSource[0].totalPoi}</span>
        </div>
      </div>
    )
  }

  multiOnlineRender() {
    return (
      <div>
        <div className="modal-item">
          <span className="mark">任务条数</span>
          <span className="content">{`${this.props.selectedRowKeys.length}条`}</span>
        </div>
        <div className="modal-item">
          <span className="mark">总POI数</span>
          <span className="content">--</span>
        </div>
      </div>
    )
  }

  multiOfflineRender() {
    return (
      <div>
        <div className="modal-item">
          <span className="mark">任务条数</span>
          <span className="content">{`${this.props.selectedRowKeys.length}条`}</span>
        </div>
      </div>
    )
  }

  markRender() {
    return (
      <div className="modal-item">
        <span className="mark">备注</span>
        <Input.TextArea
          className="content"
          value={this.state.remarks}
          onChange={e => {
            this.setState({ remarks: e.target.value })
          }}
          placeholder="如有需要，请输入备注"
          rows={4}
          maxLength={50}
        />
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.visible !== prevProps.visible &&
      this.props.visible === false
    ) {
      this.setState({ remarks: "" })
    }
  }

  render() {
    const { visible } = this.props
    if (!visible) {
      return null
    }
    this.checkType()
    return (
      <Modal
        wrapClassName="task-online-modal"
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        title={this.title}
      >
        <div className="modal-content-box">
          {this.content}
          {this.markRender()}
        </div>
      </Modal>
    )
  }
}
