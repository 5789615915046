import React from 'react';
import { Pagination } from 'antd';
import './index.scss';

export default class PaginationComponent extends React.PureComponent {
  render() {
    const { total, current, pageSize } = this.props;
    return (
      <div className='component-pagination'>
        <div className='introduce'>
          <span>共{total || 0}条记录</span>
          {total ? <span>第{current}/{Math.ceil(total / pageSize)}页</span> : <span>第0/0页</span>}
        </div>
        <div className='pagination-box'>
          <Pagination {...this.props} />
        </div>
      </div>
    )
  }
}