import { SET_USER } from "../actions/actionTypes";


export const user = (state = '', action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    default:
      return state;
  }
};