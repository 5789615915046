import request from 'axios'
import Util from './util'
import Cache from './cache'
// request.defaults.withCredentials=true;
const GET = 'get'
const POST = 'post'
const DELETE = 'delete'
const PUT = 'put'
const PATCH = 'patch'
export default {
  /**
   * 替换路径中的参数
   * @param path {String} eg  /:userId/update
   * @param param
   * @returns {{path: *, param: *}}
   */
  _resetPathWithParam: function (path, param) {
    path = path.replace(/:([a-zA-Z]+)/g, function (m, $1) {
      let s = param[$1]
      delete param[$1]
      return s
    })
    return {
      path: path,
      param: param
    }
  },
  /**
   * 发送请求
   * @param method
   * @param path
   * @param param
   */
  _doXhr: function (method, path, param, callback) {
    // let contentType = 'application/json'
    if (path.indexOf(':') >= 0) {
      let reset = this._resetPathWithParam(path, param)
      path = reset.path
      param = reset.param
    }
    let conf = {
      url: path,
      method: method
      // withCredentials: true
    }
    if (param && param._headers) {
      conf.headers = param._headers
      delete param._headers
    }
    if (method === POST || method === PUT || method === PATCH) {
      conf.data = param
    } else {
      conf.params = param
    }
    request(conf)
      .then(function (res) {
        let error = null
        if (res.status <501) {
          res = res.data || []
        } else {
          error = res.statusText
        }
        if (res.code && res.code === 1005) {
          // token invalid
          window.location.hash = '/auth'
        }
        callback && callback(error, res)
      })
      .catch(error => {
        let response = error.response || {
          status: -99999,
          statusText: 'unknown error'
        }
        callback && callback(response.data || response)
      })
  },
  /**
   * 缓存api数据
   * @param type {String} get or post
   * @param path {String} api path eg: /event/all
   * @param param {Object} 参数  eg: {id:1}
   * @param options {Object} 配置 eg: {expire: 300(s),store: sessionStorage，key: storeKey}
   * @returns {Deferred}
   */
  _cache: function (type, path, param, options, success, fail) {
    let self = this
    if (!options.key) {
      return false
    }
    let now = +new Date()
    let store = options.store || window['sessionStorage']
    let key = options.key
    let resCache = Cache.get(store, key)
    if (options.fromCache && resCache && resCache.expire >= now) {
      window.setTimeout(function () {
        // fix react setstate in constructor
        success && success(resCache.data)
      }, 10)
    } else {
      self._doXhr(type, path, param, function (err, res) {
        if (err) {
          fail && fail(err, res)
        } else {
          if (!res.err) {
            Cache.set(store, key, {
              data: res,
              expire: now + options.expire * 1000
            })
          }
          success && success(res)
        }
      })
    }
  },
  /**
   * 走缓存
   * @param type
   * @param path
   * @param param
   * @param options
   * @returns {Deferred}
   */
  _fromCache: function (type, path, param, options) {
    let d = new Util.Deferred()
    this._cache(
      type,
      path,
      param,
      options,
      function (res) {
        d.resolve(res)
      },
      function (err) {
        d.reject(err)
      }
    )
    return d
  },
  /**
   * 不走缓存，直接发请求
   * @param type
   * @param path
   * @param param
   * @returns {Deferred}
   */
  _fromNoCache: function (type, path, param) {
    let d = new Util.Deferred()
    this._doXhr(type, path, param, function (err, res) {
      if (err) {
        d.reject(err)
      } else {
        d.resolve(res)
      }
    })
    return d
  },
  handle: function (type, path, param, options) {
    if (options) {
      return this._fromCache(type, path, param, options)
    } else {
      return this._fromNoCache(type, path, param)
    }
  },
  get: function (path, param, options) {
    if (options) {
      return this._fromCache(GET, path, param, options)
    } else {
      return this._fromNoCache(GET, path, param)
    }
  },

  post: function (path, param, options) {
    if (options) {
      return this._fromCache(POST, path, param, options)
    } else {
      return this._fromNoCache(POST, path, param)
    }
  },
  delete: function (path, param, options) {
    if (options) {
      return this._fromCache(DELETE, path, param, options)
    } else {
      return this._fromNoCache(DELETE, path, param)
    }
  },
  put: function (path, param, options) {
    if (options) {
      return this._fromCache(PUT, path, param, options)
    } else {
      return this._fromNoCache(PUT, path, param)
    }
  },
  patch: function (path, param, options) {
    if (options) {
      return this._fromCache(PATCH, path, param, options)
    } else {
      return this._fromNoCache(PATCH, path, param)
    }
  }
}
