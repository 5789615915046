import Http from "../util/httpUtils"
import Env from "../util/env"
import {
  TASK_API,
  STPAPI,
  MAPAPI,
  LOGININ_API,
  DISTRICTS_API,
  TEMP_API,
  ACCESS_KEY,
  DISTRICT_ACCESS_KEY,
  BLACK_LIST_API,
} from "../constants/conf"
import { VERSION } from "../constants/version"

/**
 * 添加header
 * @param {*} params
 */
function wrapHeaders(api, path, params) {
  // params = params || {}
  // let Authorization = Env.getUser().token;
  // // return params
  // params = params || {}
  // params._headers = {Authorization}
  const version =
    "web-" +
    VERSION.split(".")
      .slice(0, 3)
      .join(".")

  if (!(api === DISTRICTS_API && path === "/maps/place/v1/districts")) {
    params._headers = { "Client-Info": version } // 'web-1.1.0'
  }

  return params
}

function repairPath(api, path) {
  let intactPath = api + path
  if (
    (api === TASK_API ||
      api === STPAPI ||
      api === LOGININ_API ||
      api === BLACK_LIST_API) &&
    ACCESS_KEY
  ) {
    intactPath += `?access_key=${ACCESS_KEY}`
  } else if (
    api === DISTRICTS_API &&
    path === "/maps/place/v1/districts" &&
    DISTRICT_ACCESS_KEY
  ) {
    intactPath += `?access_key=${DISTRICT_ACCESS_KEY}`
  }

  return intactPath
}

function get(api, path, param, opt) {
  param = param || {}
  return Http.get(repairPath(api, path), wrapHeaders(api, path, param), opt)
}

function _delete(api, path, param) {
  return Http.delete(repairPath(api, path), wrapHeaders(api, path, param))
}

function post(api, path, param) {
  return Http.post(repairPath(api, path), wrapHeaders(api, path, param))
}

function patch(api, path, param) {
  return Http.patch(repairPath(api, path), wrapHeaders(api, path, param))
}
function put(api, path, param) {
  return Http.put(repairPath(api, path), wrapHeaders(api, path, param))
}

// function mockResultWith(data) {
//   let d = new Util.Deferred();
//   setTimeout(() => {
//     d.resolve(data);
//   }, 100);
//   return d;
// }

export default {
  login: function(param) {
    return post(LOGININ_API, "/rope/v1/front/login", param)
  },
  project: {
    /**
     * 项目列表
     */
    list: function(project_id, project_name, status) {
      let param = { project_id, project_name, status }
      return get(TASK_API, "/positioning/survey/v1/projects", param)
    },
    create: function(param) {
      return post(TASK_API, "/positioning/survey/v1/projects", param)
    },
    // 开始
    start: function(projectId, status) {
      let param = { projectId, status }
      return patch(
        TASK_API,
        "/positioning/survey/v1/projects/" + projectId,
        param
      )
    },
    delete: function(projectId) {
      let param = { projectId }
      return _delete(
        TASK_API,
        "/positioning/survey/v1/projects/" + projectId,
        param
      )
    },
  },

  task: {
    // 任务列表
    list: function(param) {
      //return get(TASK_API, "/rope/v1/tasks", param);
      return get(LOGININ_API, "/rope/v1/front/tasks", param)
    },
    // 修改任务
    update: function(task_id, state, subState, projectId, floor) {
      let param = { task_id, state, subState, projectId, floor }
      return patch(TASK_API, "/positioning/survey/v1/tasks/" + task_id, param)
    },
    create: function(param) {
      return post(TASK_API, "/rope/v1/tasks", param)
    },
    // 操作记录列表
    activityList: function(id, param) {
      return get(TASK_API, `/rope/v1/tasks/${id}/activities`, param)
    },
    //--------------------
    changeState: function(id, param) {
      //return post(TASK_API, `/rope/v1/tasks/${id}/transitions`, param);
      return post(LOGININ_API, `/rope/v1/front/tasks/${id}/transitions`, param)
    },
    //导出task信息以及point的定位信息
    exportCsv: function(id, param) {
      return TASK_API, `/rope/v1/front/export?task_id=${id}`, param
    },
  },

  createTask: {
    // 任务列表
    list: function(param) {
      // return get(LOGININ_API, "/rope/v1/front/place/search", param);
      return get(LOGININ_API, `/rope/v1/front/place/search`, param)
    },
    release: function(param) {
      return post(LOGININ_API, `/rope/v1/front/task/create`, param)
    },
  },

  surveyTasks: {
    // POI明细列表
    poiList: function(id, param) {
      return get(STPAPI, `/rope/v1/surveytasks/${id}/points`, param)
    },

    // 基本信息列表
    deteiledInformation: function(id, param) {
      return get(STPAPI, `/rope/v1/surveytasks/${id}/stats`, param)
    },
    //更新survey task place审核信息
    updateSurveyTaskPlace: function(id, placeId, param) {
      return put(
        STPAPI,
        `/rope/v1/surveytasks/${id}/approvals/places/${placeId}`,
        param
      )
    },
    //更新survey task point审核信息
    updateSurveyTaskPoint: function(id, pointsId, param) {
      return put(
        STPAPI,
        `/rope/v1/surveytasks/${id}/approvals/points/${pointsId}`,
        param
      )
    },
    //一键更新所有未审核的survey task point审核信息为有效
    updateSurveyTaskPointAll: function(id, param) {
      return put(
        STPAPI,
        `/rope/v1/surveytasks/${id}/approvals/unapproved/all`,
        param
      )
    },
    //获取survey task审核信息

    getSurveyTaskInformation: function(id, param) {
      return get(STPAPI, `/rope/v1/surveytasks/${id}/approvals`, param)
    },
  },

  // task detail page
  taskDetail: {
    getTaskInfo: id => {
      return get(TASK_API, `/rope/v1/tasks/${id}`)
    },
    getCollectPhotoList: (id, params) => {
      return get(STPAPI, `/rope/v1/surveytasks/${id}/places`, params)
    },
    //获取任务照片列表
    getImageUrl: (id, params) => {
      return get(STPAPI, `/rope/v1/surveytasks/${id}/images`, params)
    },
  },

  // temp page
  tempDetail: {
    getInfo: params => {
      return get(TEMP_API, `/positioning/survey/v1/surveys`, params)
    },
  },

  publish: {
    list: function(state, publish_id, task_id, building_name, city_name) {
      let param = { state, publish_id, task_id, building_name, city_name }
      return get(TASK_API, "/positioning/survey/v1/publish", param)
    },
    // 同步
    sync: function(taskIds, subject) {
      let param = { taskIds, subject }
      return post(TASK_API, "/positioning/survey/v1/publish/sync", param)
    },
    // 撤回
    withdraw: function(taskId, subject) {
      let param = { taskId, subject }
      return post(TASK_API, "/positioning/survey/v1/publish/withdraw", param)
    },
    // 提交学习
    submission: function(taskId) {
      let param = { taskId }
      return post(TASK_API, "/positioning/survey/v1/publish/submission", param)
    },
    //提交验证
    verification: function(taskId, environment, engine) {
      let param = { taskId, environment, engine }
      return post(
        TASK_API,
        "/positioning/survey/v1/publish/verification",
        param
      )
    },
    //获取验证结果
    verificationList: function(task_id, environment, engine) {
      let param = { task_id, environment, engine }
      return get(TASK_API, "/positioning/survey/v1/publish/verification", param)
    },
  },
  surveyAdmin: {
    list: function(task_id) {
      let param = { task_id }
      return get(TASK_API, "/positioning/survey/v1/admin/floor-states", param)
    },
  },
  surveyPattern: {
    list: function(survey_pattern_id, building, floor) {
      let param = { survey_pattern_id, building, floor }
      return get(TASK_API, "/positioning/survey/v1/patterns", param)
    },
    // 创建获取survey_pattern_id
    create: function(
      building,
      floor,
      name,
      description,
      tags,
      BoundingArea,
      points
    ) {
      let param = {
        building,
        floor,
        name,
        description,
        tags,
        BoundingArea,
        points,
      }
      return post(TASK_API, "/positioning/survey/v1/patterns", param)
    },
    // 创建学习点和验证点
    createPoint: function(survey_pattern_id, surveyPoints) {
      let param = { survey_pattern_id, surveyPoints }
      return post(
        TASK_API,
        "/positioning/survey/v1/patterns/" + survey_pattern_id + "/points",
        param
      )
    },
    delete: function(survey_pattern_id, survey_point_id) {
      return _delete(
        TASK_API,
        "/positioning/survey/v1/patterns/" +
          survey_pattern_id +
          "/points/" +
          survey_point_id
      )
    },
    modify: function(surveyPatternId, surveyPoints) {
      let param = { surveyPatternId, surveyPoints }
      return patch(
        TASK_API,
        "/positioning/survey/v1/patterns/" + surveyPatternId + "/points",
        param
      )
    },
    clear: function(surveyPatternId) {
      return _delete(
        TASK_API,
        "/positioning/survey/v1/patterns/" + surveyPatternId + "/clear/points"
      )
    },
  },
  survey: {
    // 查询采集数据
    list: function(task_id, building, floor) {
      let param = { task_id, building, floor }
      return get(TASK_API, "/positioning/survey/v1/surveys", param)
    },
  },
  map: {
    list: function() {
      let param = {}
      return Http.get(MAPAPI + "/indoormaps/v1/all", param, {
        store: sessionStorage,
        fromCache: true,
        key: "building_list",
        expire: 36000,
      })
    },
    change: function(building_id, floor_name) {
      let param = {}
      return Http.get(
        MAPAPI + "/indoormaps/v1/" + building_id + "/" + floor_name + ".json",
        param
      )
    },
  },
  user: {
    /**
     * 用户列表
     */
    list: function(pn, ps, roleList, asc, name, teamid = []) {
      let param = { ps, pn, asc, name }
      return get("/user/query", param)
    },
    blackList: function(params) {
      return get(BLACK_LIST_API, "/rope/v1/black_users", params)
    },
    recovery: function(params) {
      return _delete(BLACK_LIST_API, "/rope/v1/black_users", params)
    },
    addBlackList: function(params) {
      return post(BLACK_LIST_API, "/rope/v1/black_users", params)
    },
  },
  taskOnline: {
    update: function(taskId, params) {
      return patch(STPAPI, `/rope/v1/surveytasks/${taskId}`, params)
    },
  },
  districts: {
    list: function(params) {
      // http://api.newayz.local/rope/v1/black_users?page_size=2
      return get(DISTRICTS_API, "/maps/place/v1/districts", params)
    },
  },

  reportManagement: {
    dashboard: function(params) {
      return get(LOGININ_API, "/rope/v1/front/reports/dashboard_report", params)
    },
    collection: function(params) {
      return get(
        LOGININ_API,
        "/rope/v1/front/reports/collect_check_total_report",
        params
      )
    },
  },
  fieldManagement: {
    create: function(params) {
      return post(LOGININ_API, "/rope/v1/front/field_task/create", params)
    },
  },
}
